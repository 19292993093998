import styled from "styled-components";
import { devices } from "../../theme/devices";
export const CheckmarkIcon = styled.span`
  width: 29px;
  height: 29px;
  background-color: rgba(0, 56, 97, 1);
  border-radius: 50%;
  position: absolute;
  color: #fff;
  top: -17%;
  left: -3%;
  padding: 0px;
  text-align: center; /* Center the checkmark icon horizontally */
  line-height: 29px;
  // color: green; /* Change the color of the checkmark */
  font-size: 20px; /* Adjust the size of the checkmark */
  display: ${(props) => (props.isVisible ? "block" : "none")};
  @media${devices.xxl} {
    width: 27.934px;
    height: 27.934px;
  }
  @media${devices.sm} {
    width: 15.23px;
    height: 15.23px;
    font-size: 14px;
    line-height: 15px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin: 4% 0;
  cursor: pointer;
  // width: 449px;
  // height: 100px;
`;
export const StyledInput = styled.div`
  width: 400px;
  height: 90px;
  flex-shrink: 0;
  border-radius: ${(props) => (props.checked ? "5px" : "10px")};
  border: ${(props) =>
    props.checked ? "1.5px solid #003861" : "1px solid #d9d9d9"};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: start-flex;
  align-items: center;
  gap: 30px;
  &: hover {
    border-radius: 5px;
    border: 1.5px solid #003861;
    background: #fff;
  }
  @media${devices.xxl} {
    width: 381.501px;
    height: 86.325px;
  }
  @media${devices.md} {
    width: 375.02px;
    height: 84.882px;
  }
  @media${devices.sm} {
    width: 235.797px;
    height: 52.516px;
    gap: 15px;
  }
`;
export const StyledIcon = styled.div`
  width: ${(props) => (props.checked ? "68px" : "56px")};
  height: ${(props) => (props.checked ? "68px" : "56px")};
  flex-shrink: 0;
  margin-left: 8%;
  color: ${(props) =>
    props.checked ? "rgba(0, 56, 97, 1)" : "rgba(206, 206, 206, 1)"};
  ${StyledInput}:hover & {
    color: rgba(0, 56, 97, 1);
    width: 68px;
    height: 68px;
  }
  @media${devices.xxl} {
    width: ${(props) => (props.checked ? "65.501px" : "53.942px")};
    height: ${(props) => (props.checked ? "65.501px" : "53.942px")};
    ${StyledInput}:hover & {
      width: 65.501px;
      height: 65.501px;
    }
  }
  @media${devices.md} {
    width: ${(props) => (props.checked ? "64.52px" : "53.134px")};
    height: ${(props) => (props.checked ? "64.52px" : "53.134px")};
    ${StyledInput}:hover & {
      width: 64.52px;
      height: 64.52px;
    }
  }
  @media${devices.sm} {
    width: ${(props) => (props.checked ? "35px" : "29.409px")};
    height: ${(props) => (props.checked ? "35px" : "29.409px")};
    ${StyledInput}:hover & {
      width: 35px;
      height: 35px;
    }
  }
`;
export const StyledP = styled.p`
  color: ${(props) => (props.checked ? "#003861" : "#818590")};
  text-align: center;
  font-family: Roboto;
  font-size: ${(props) => (props.checked ? "24px" : "20px")};
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  ${StyledInput}:hover & {
    color: #003861;
    font-size: 24px;
  }
  @media${devices.md} {
    font-size: ${(props) => (props.checked ? "22px" : "20px")};
    ${StyledInput}:hover & {
      font-size: 22px;
    }
  }
  @media${devices.sm} {
    font-size: ${(props) => (props.checked ? "16px" : "14px")};
    ${StyledInput}:hover & {
      font-size: 16px;
    }
  }
`;

export const RadioInput = styled.input`
  opacity: 0;
  cursor: pointer;
`;
