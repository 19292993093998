import React,{useState} from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import styles from './adminLogin.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROOT_URL } from '../../../utils/variable';
function AdminLogin() {

    const navigate=useNavigate();

    const [formData, setFormData] = useState({
        identifier:'',
        password: '',
    });

    const [errorMessage,setErrorMessage]=useState(''); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Form Data:",formData)

        try{
        // Make a POST request to your API endpoint
        const data=await axios.post(`${ROOT_URL}/login`,formData)
        
            if(data.data.status === 'error') /*For Handling Error */
            {
                console.log("Response Status:",data.status)
                console.log("Error Message:",data.data.message)
                if (typeof data.data.message === 'string') {
                    setErrorMessage(data.data.message); // Single error message
                } else if (typeof data.data.message === 'object') {
                    // Object with multiple fields
                    const errorFields = Object.values(data.data.message).join(' - ');
                    setErrorMessage(errorFields);
                }
                
            }
            else //If No error status
            { 
                console.log("Response",data)
                console.log("Response Status:",data.status)
                console.log("Error:",data)

                localStorage.setItem('Token',data.data.token);
                navigate('/admin-welcome');
                // Clear the form after successful submission
                setFormData({
                    identifier:'',
                    password: '',
                });
            }   //End else part
        }catch(error){
            console.log('Error on sending Post request:',error.response.data);
            if(error && error.response.data.status === 'error'){
                setErrorMessage(error.response.data.message);
            }
        }
    };
    const handleChange = (e) => {
        console.log('handleChange')
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
   
    return (
        <div id='adminLogin' className={`${styles.container} container-fluid d-flex flex-column justify-content-center align-items-center`}>
            <div>
                <img src="./../Assets/adminPanel/login/image 20.png" alt="Logo" className={`${styles.logo}`} />
                <Card className={styles.loginCard}>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <div className='text-center'>
                                <div className={`d-flex justify-content-center`}>
                                    <img src="./../Assets/adminPanel/login/Featured icon.png" alt="Logo" className={styles.featuredIcon} />
                                </div>
                                <h6 className={`${styles.heading}`}>
                                    Log in to your account
                                </h6>
                                <p className={`${styles.para}`}>
                                    Welcome back! Please enter your details.
                                </p>
                            </div>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className={styles.formLabel}>Username/Email</Form.Label>
                                <Form.Control type="text" placeholder="Enter email or Username" name='identifier' value={formData.identifier} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword" className='pt-3'>
                                <Form.Label className={styles.formLabel}>Password</Form.Label>
                                <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                                />
                            </Form.Group>
                            {errorMessage && (
                                <div className={`${styles.errormessage}`}>
                                    {errorMessage}
                                </div>
                            )}
                            <Button variant="" type="submit" className={styles.loginButton} onClick={handleSubmit}>
                                Log in
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}

export default AdminLogin;
