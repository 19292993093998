import React,{useEffect,useState} from 'react';
import {Button,Table,Col,Row,Form,Container} from 'react-bootstrap';
import styles from './dropIn.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dropReportStyle.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx'; // Import xlsx library
import { ROOT_URL } from '../../../utils/variable';
function DropInCheckout(){
    
    const navigate=useNavigate();

    const [dropInData,setdropInData]=useState([]);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchMode, setSearchMode] = useState(true); 

   
    useEffect(()=>{
        const getData=async()=>{
            axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('Token')}`;
            axios.defaults.headers.post["Content-Type"] = "application/json";
            //console.log('token',localStorage.getItem('Token'));
            //Send get request and store data
            try{
                console.time();
                const response=await axios.get(`${ROOT_URL}/dropIn/report`);
                console.timeEnd();
                if(response.data.status === 'error'){
                    console.log("Error in the data:",response.data)
                    //console.log("Response Status:",response.data.status)
                }
                else{
                    console.log('Original Response:',response.data.data)
                    setdropInData(response.data.data.dropIns);
                }
            }
            catch(error){
                if (error && error.response && error.response.status === 401) {
                    //console.log("401 error");
                    navigate('/admin-login'); // Navigate to your error page
                }
            }
        }
        getData();
    },[navigate])
   
    const handleSubmit=async(e)=>{
        e.preventDefault();
        setSearchMode(!searchMode);
        if(searchMode){
            try{
                const response = await axios.get(`${ROOT_URL}/dropIn/report`, {
                    params: {
                        start_date: fromDate, 
                        end_date: toDate,     
                    }
                });
                if(response.data.status === 'error'){
                    console.log("Error in the data:",response.data)
                    console.log("Response Status:",response.data.status)
                }
                else{
                    console.log("Response on filter:",response.data.data)
                    setdropInData(response.data.data.dropIns);
                }
            }catch(error){
                if (error && error.response && error.response.status === 401) {
                    console.log("401 error");
                    navigate('/admin-login'); // Navigate to your error page
                }
            }
        }
        else{
            setFromDate('');
            setToDate('');
            window.location.reload();        
        }
    }
    const Backbtn=(e)=>{
        e.preventDefault();
        navigate(-1)
    }
    function formatDateTime(dateTimeString) {
        console.log('Date in  DropIn:',dateTimeString);
        //if dateTimeString is null
        if (!dateTimeString) {
            return 'No Checkout yet!';
        }
        const date = new Date(dateTimeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
      
        const formattedDateTime = `${hours}:${minutes}:${seconds} (${day}-${month}-${year})`;
        
        console.log('Date in  DropIn formatted Data:',formattedDateTime);

        return formattedDateTime;
    }
    const PrintPage=(e)=>{
        e.preventDefault();
        window.print();
    }

    const downloadData=()=>{
        const wb = XLSX.utils.book_new(); // Create a new workbook

        // Check if dropInData is an array or a single object
        const dataToExport = Array.isArray(dropInData)
          ? dropInData.map((data) => ({
              Name: data.name ?? '',
              'Staff Name': data.staff_name ?? '',
              'Time In': data.time_in ? formatDateTime(data.time_in) : '',
              'Time Out': data.time_out ? formatDateTime(data.time_out) : '',
              Duration: data.duration ?? '',
              'Areas Used': data.areas_used ? JSON.parse(data.areas_used).join(', ') : '',
            }))
          : [
              {
                Name: dropInData?.name ?? '',
                'Staff Name': dropInData?.staff_name ?? '',
                'Time In': dropInData?.time_in ? formatDateTime(dropInData?.time_in) : '',
                'Time Out': dropInData?.time_out ? formatDateTime(dropInData?.time_out) : '',
                Duration: dropInData?.duration ?? '',
                'Areas Used': dropInData?.areas_used ? JSON.parse(dropInData.areas_used).join(', ') : '',
              },
            ];
    
        const ws = XLSX.utils.json_to_sheet(dataToExport); // Convert to worksheet
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1'); // Append worksheet to workbook
        XLSX.writeFile(wb, 'DropIn-Center Data.xlsx'); // Save workbook as an Excel file
    }
    return(
        <div id='dropReport'>
            <div className={`${styles.container} container-fluid`}>
                <div className={`d-flex justify-content-between p-3`}>
                    <div>
                        <img src="./../Assets/adminPanel/login/image 20.png" alt="Logo" className={styles.logo} />
                    </div>
                    <div className='p-3'>
                        <Button variant="" type="submit" className={styles.logoutButton} onClick={Backbtn}>
                            BACK 
                            <span style={{fontSize:"18px"}}>
                            {'→'}
                            </span>
                        </Button>
                    </div>
                </div>
                <Container className='pb-5'>
                    <Form method="POST" onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-center"> {/* Center the content horizontally */}
                            <Row className="justify-content-center"> {/* Center the row horizontally for all screen sizes */}
                                <Col xs="auto" className="text-center"> {/* Use xs="auto" to center the items horizontally for all screen sizes */}
                                    <div className="form-group input-group input-daterange">
                                        <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(new Date(date))}
                                        placeholderText="From"
                                        id="datepicker1"
                                        type="date"
                                        name="from_date"
                                        className="p-2 date"
                                        maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
                                        dateFormat="dd-MM-yyyy"
                                        required
                                        />
                                    </div>
                                </Col>
                                <Col xs="auto" className="text-center">
                                    <div className="form-group input-group input-daterange">
                                        <DatePicker
                                        selected={toDate}
                                        onChange={(date) => setToDate(new Date(date))}
                                        placeholderText="To"
                                        id="datepicker2"
                                        type="date"
                                        className="p-2 date"
                                        maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
                                        dateFormat="dd-MM-yyyy"
                                        required
                                        />
                                    </div>
                                </Col>
                                <Col xs="auto" className="text-center">
                                    <Button type="submit" variant="year" className={`${styles.searchBtn}`}>
                                        {searchMode ? 'Search' : 'Reset'}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Container>
                <div className="col-md-9 mx-auto text-center">
                    {dropInData && dropInData?.length>0?
                    <div className="table-responsive table-container">
                        <Table bordered size="xl">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Staff Name</th>
                                <th>Time In</th>
                                <th>Time Out</th>
                                <th>Duration</th>
                                <th>Areas Used</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dropInData?.map((data, index) => (
                            <tr key={index}>
                                <td>{data.name}</td>
                                <td>{data.staff_name}</td>
                                <td>{formatDateTime(data.time_in)}</td>
                                <td>{formatDateTime(data.time_out)}</td>
                                <td>{data.duration}</td>
                                <td>{JSON.parse(data.areas_used)?.join(', ')}</td>
                            </tr>
                            ))}
                        </tbody>
                        </Table>
                    </div>
                    : ( // Show "No Data Available" after the delay
                        <h5 className={styles.noData}>No Data Available</h5>
                    )}
                </div>
                <div>
                    <div class='container-fluid'>
                        <Row className="py-4">
                            <Col xs={12} className="d-flex justify-content-end p-3">
                            <Button className={`${styles.downloadbtn} mx-3`} onClick={downloadData}>
                                    Download Data
                                </Button>
                                <Button className={`${styles.downloadbtn}`} onClick={PrintPage}>
                                    Print This Page
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div> 
            </div>
        </div>
    )
}
export default DropInCheckout;