import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import './style.css';

const TableData = ({ fullData }) => {
    const [descriptions, setDescription] = useState([]);

    useEffect(() => {
        if (fullData && Array.isArray(fullData)) {
            setDescription(fullData);
        } else if (fullData && typeof fullData === 'object' && Object.keys(fullData).length > 0) {
            setDescription(Object.values(fullData));
        }

        console.log('Full Data:', fullData);
    }, [fullData]);

    return (
        <div>
            <h4 className='text-center py-4'>
            Volunteers Information
            </h4>
            {descriptions.length > 0 ? (
                <div className="container" id='Surveytable'>
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                            <div className="table-responsive table-container">
                                <Table striped bordered hover size="lg">
                                    <thead>
                                        <tr>
                                            {descriptions.length > 0 &&
                                                Object.keys(descriptions[0]).map((key, index) => (
                                                    <th key={index} style={{textTransform:"capitalize"}}>{key}</th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {descriptions.map((data, dataIndex) => (
                                            <tr key={dataIndex}>
                                                {Object.values(data).map((value, valueIndex) => (
                                                    <td key={valueIndex}>{value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <h6 className="text-center py-3">No Volunteering Data Found!</h6>
            )}
        </div>
    );
};

export default TableData;
