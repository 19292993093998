import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting";
import PieChart from "highcharts-react-official";
import TableData from "highcharts/modules/export-data";
import HighchartsData from "highcharts/modules/data";
import { Table } from "react-bootstrap";
import './style.css';

exporting(Highcharts);
HighchartsData(Highcharts);
TableData(Highcharts);

const Chart = ({ chartData, fullData, table,tableDescription,chartTitle }) => {

    console.log('Chart Data:',chartData);
    const [disablePointerEvents, setDisablePointerEvents] = useState(false);
    const [descriptions, setDescription] = useState([]);

    useEffect(() => {
        let processedData = [];
    
        if (fullData && Array.isArray(fullData)) {
            // Handle the array case
            processedData = fullData;
        } else if (fullData && typeof fullData === 'object' && Object.keys(fullData).length > 0) {
            // Handle the object case
            processedData = Object.keys(fullData).map((key) => fullData[key]);
        }
    
        if (processedData.length > 0) {
            // Do something with the processed data
            setDescription(processedData);
        }
        console.log('Full Data:', fullData);
    }, [fullData]);
    
    

    const handleWheel = () => {
        setDisablePointerEvents(true);
    };
    // eslint-disable-next-line
    const resetPointerEvents = () => {
        setDisablePointerEvents(false);
    };
    
    useEffect(() => {
        if (resetPointerEvents) {
            setDisablePointerEvents(false);
        }
    }, [resetPointerEvents]);

    // Format your data
    const data = Object.entries(chartData).map(([name, y]) => ({
        name,
        y,
    }));

    // Define the chart options
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            text: chartTitle,
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.y:.1f}%',
                },
            },
        },
        accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          tooltip: {
            pointFormatter: function () {
              return (
                '<b>' +
                this.name +
                '</b>: ' +
                `${this.y.toFixed(1)}%)`
              );
            },      
          },
        series: [
            {
                name: 'Percentage',
                colorByPoint: true,
                data,
            },
        ],
    };

    return (
        <div>
            {chartData && Object.keys(chartData).length > 0 ? (
                <div
                    onWheel={handleWheel}
                    onMouseOut={resetPointerEvents}
                    onMouseOver={resetPointerEvents}
                    onMouseEnter={resetPointerEvents}
                    style={{ pointerEvents: disablePointerEvents ? "none" : "auto" }}
                    className='highcharts-data-table py-3'
                >
                    <PieChart highcharts={Highcharts} options={options} />
                </div>
            ) : null}
            {table === "true" && descriptions.length > 0 ? (
                <div className="container" id='Surveytable'>
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center py-4">
                            <div className="table-responsive table-container">
                                <Table bordered size="lg">
                                    <thead>
                                        <tr>
                                            <th>Sr.no</th>
                                            <th>{tableDescription}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {descriptions.map((description, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : table === "true" ? (
                <h6 className="text-center py-3">No Other Data Found!</h6>
            ) : null}
        </div>
    );
};

export default Chart;
