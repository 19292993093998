import React from "react";
import { useNavigate } from "react-router-dom";
import { ServicesData } from "../data/serviceData";

import {
  StyledNavbar,
  StyledLogo,
  StyledBackground,
  StyledCard,
  StyledCardContainer,
  StyledInnerCard,
  StyledInfo,
  StyleContainer1,
  StyledCardService,
  MainHeading,
  HeadingA,
  Customhr,
  StyledCard1,
  FImage,
  FeatureData1,
  FeatureImageWrapper,
  FeatureName,
  MainContainer
} from "./style";

const Section2 = () => {
  const navigate = useNavigate();
  const handleOptionClick = (service) => {
    navigate(`/section3/${service.name}`);
  };
  return (
    <MainContainer>
      <StyledInfo>
        <StyledNavbar>
          <StyledLogo src="../logo1.png" alt="Logo" />
        </StyledNavbar>
        <StyleContainer1>
          <StyledBackground>
            <StyledCardService>
              <StyledCard>
                <MainHeading>
                  <HeadingA>Select the Category</HeadingA>
                  <Customhr />
                </MainHeading>
                <StyledCard1>
                  {ServicesData.map((service, index) => (
                    <StyledCardContainer
                      key={index}
                      onClick={() => {
                        if (service.name === 'CICIL Services') {
                          navigate(`/service/CICIL`);
                        } else {handleOptionClick(service)}
                      }}
                    >
                      <StyledInnerCard>
                        <FImage>
                          <FeatureImageWrapper>
                            {service.icon}
                          </FeatureImageWrapper>
                        </FImage>
                        <FeatureData1>
                          <FeatureName>{service.name}</FeatureName>
                        </FeatureData1>
                      </StyledInnerCard>
                    </StyledCardContainer>
                  ))}
                </StyledCard1>
              </StyledCard>
            </StyledCardService>
          </StyledBackground>
        </StyleContainer1>
      </StyledInfo>
    </MainContainer>
    
  );
};

export default Section2;
