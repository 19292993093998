import React from "react";
import {
  Input,
  StyledContainer,
  StyledIcon,
  StyledInput,
  StyledP,
} from "./styles";

function Input3({ icon, label, placeholder, name, onChange, type, error,value }) {
  return (
    <StyledContainer>
      <StyledP>{label}</StyledP>
      <StyledInput error={error}>
        <StyledIcon>{icon}</StyledIcon>
        {type === "time" && (
          <Input
            type="time"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        )}
        {type === "tel" && (
          <Input
            type="tel"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
          />
        )}
        {!type && (
          <Input
            type={type || "text"}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value} 
          />
        )}
      </StyledInput>
    </StyledContainer>
  );
}

export default Input3;
