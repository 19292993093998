import React from "react";
import {
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledCross,
  StyledH3,
  StyledImage,
  StyledImgContainer,
  StyledLine,
  StyledLogo,
  StyledLogoContainer,
  StyledMain,
  StyledP,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { RxCross1, RxCross2 } from "react-icons/rx";
function WelcomePage() {
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/services");
  };
  return (
    <StyledMain>
      <StyledLogoContainer>
        <StyledLogo src="../Logo.png" alt="logo"></StyledLogo>
      </StyledLogoContainer>
      <StyledContainer>
        <StyledH3>Welcome to CICIL</StyledH3>
        <StyledLine></StyledLine>
        <StyledContent>
          <StyledImgContainer>
            <StyledImage src="../image.png" alt="img"></StyledImage>
          </StyledImgContainer>
          <StyledP>
            We are glad that you are here. We need some information to proceed.
          </StyledP>
          <StyledButton onClick={navigateTo}>Get Started</StyledButton>
        </StyledContent>
      </StyledContainer>
    </StyledMain>
  );
}

export default WelcomePage;
