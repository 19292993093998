import React from "react";
import {
  StyledErrorContainer,
  ErrorIcon,
  ErrorMessage,
  ErrorContent,
} from "./style";
import { BiErrorAlt } from "react-icons/bi";

const StyledErrorComponent = ({ message }) => {
  return (
    <ErrorContent>
      <StyledErrorContainer>
        <ErrorIcon>
          <BiErrorAlt />
        </ErrorIcon>
        <ErrorMessage>{message}</ErrorMessage>
      </StyledErrorContainer>
    </ErrorContent>
  );
};

export default StyledErrorComponent;
