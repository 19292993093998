import React from 'react';
import { useNavigate } from "react-router-dom";
import {PageContainer,Card,Image1,ThankYouMessage,CopyrightMessage,Card1,Image2,Img3,Img4,Button,Button1} from './style'

export const Submission = () => {
  const navigate = useNavigate();
  
  const handleBaCkToHome = () => {
    navigate("/");
  };
  return (
    <PageContainer>
     <Img3>
      <Img4 src="../top.png" alt="lines"/>
    </Img3>
    <Card1>
      <Card>
       <Image2><Image1 src="../submission.png" alt="submitimage" /></Image2> 
        <ThankYouMessage>Thank you for the submission!</ThankYouMessage>
        <Button> <Button1 onClick={handleBaCkToHome}>Back to Home</Button1> </Button>
      </Card>
      </Card1>
      <CopyrightMessage>Copyright 2023 © CICIL All rights reserved.</CopyrightMessage>
    </PageContainer>
  );
};

export default Submission;