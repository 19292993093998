import styled from "styled-components";
import { ResponsiveImage } from "../data/serviceData";

export const StyledInfo = styled.div`
  margin: 0;
  padding: 0;
  background-image: url(../back.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: scroll;
  justify-content: center;
  flex-shrink: 0;
  background-attachment: scroll;
  background-position: center;
  /* @media only screen and (min-width: 1911px) and (max-width: 2100px) {
    height: 1100px;
    background-size: 100%;
    flex-shrink: 0;
    background-attachment: scroll;
    transform: translateY(24px);
  }

  @media only screen and (min-width: 1820px) and (max-width: 1910px) {
    height: 1000px;
    background-size: 100%;
    flex-shrink: 0;
    background-attachment: scroll;
    transform: translateY(24px);
  } */

  /* @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    transform: translateY(76px);
    background-size: 100%;
    background-position: center;
  } */
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    transform: translateY(160px);
    background-size: 100%;
    background-position: center;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    transform: translateY(160px);
    background-size: 100%;
    background-position: center;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    background-size: 100%;
    background-position: center;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 100%;
    background-size: 100%;
    background-position: center;
  }
`;
export const StyledNavbar = styled.div`
  background-color: #fbfcff;
  padding: 10px;
  /* @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    transform: translateY(-59px);
  } */
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    transform: translateY(-159px);
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    transform: translateY(-156px);
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    transform: translateY(-59px);
    margin-top: 61px;
  }
`;

export const StyledLogo = styled.img`
  height: 89px;
  width: 89px;
  margin: 1% 2%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin: 2%;
    width: 80px;
    height: 76px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin: 2%;
    width: 74px;
    height: 70px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 68px;
    height: 65px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 44px;
    height: 42px;
  }
`;
export const StyleContainer1 = styled.div`
  width: 100%;
  margin: -3% 0;
  min-height:70vh;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin: -4% 0;
  }
`;
export const StyledBackground = styled.div`

  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 76vh;
 
`;
export const CloseButton = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin-top: 22%;
  width: 100%;
  height: 40px;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 12px;
    height: 33px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 4px;
    height: 33px;
  }
`;
export const CBtn = styled.div`
  margin: 1% 4% 0 0;
  float: right;
  width: 44px;
  height: 44px;
  color: rgba(14, 59, 114, 1);
  border-radius: 20px;
  cursor: pointer;
  @media only screen and (min-width: 811px) and (max-width: 1536px) {
    margin: 0;
  }

  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 15.13px;
    height: 15.13px;
    margin: 1% 4% 0 0;
  }
`;
export const CloseIcon = styled.img`
  width: 15.8px;
  height: 15.8px;
`;
export const StyledCardService = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    min-height: auto;
  }
`;
export const StyledCard = styled.div`
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 4px 43px 49px rgba(0, 0, 0, 0.07);
  background-size: cover;
  border-radius: 10px;
  height: auto;
  width: 962px;
  backdrop-filter: blur(125px);
  padding-bottom:40px;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 875px;
    transform: translateY(-127px);
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 645px;
    transform: translateY(-155px);
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 504px;
    transform: translateY(-5px);
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 130%;
    height: 708px;
    backdrop-filter: blur(131px);
    transform: translateY(-54px);
  }
`;

export const MainHeading = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 5%;

  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    margin-top: 8%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: 10%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 11%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    margin-top: 24%;
  }
`;

export const HeadingA = styled.div`
  font-size: 40px;
  font-weight: 700;
  height: 30px;
  line-height: 20px;
  color: #0e3b72;
  font-family: Roboto !important;
  @media only screen and (min-width: 1081px) and (max-width: 1536px) {
    font-size: 38px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 18px;
  }
`;

export const Customhr = styled.hr`
  width: 78%;
  color: rgba(14, 59, 114, 1);
`;
export const StyledCard1 = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 0fr);
  grid-gap: 5px;
  grid-column-gap: 5px;
  margin-top: 3%;

  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    margin-top: 5%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 9%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    grid-gap: 11px;
  }
`;
export const FImage = styled.div`
  align-items: center;
  justify-content: center;
`;
export const FeatureData1 = styled.div`
  align-items: center;
  justify-content: center;
  width: 57%;
  margin-top: 4%;
  @media only screen and (min-width: 320px) and (max-width: 810px) {
    width: 65%;
  }
`;

export const FeatureName = styled.div`
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
  line-height: 25.5px;
  color: #0e3b72;
  &:hover {
    font-size: 24px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    line-height: 23px;
    font-size: 20px;
    &:hover {
      font-size: 24px;
      line-height: 22.5px;
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    line-height: 17.5px;
    font-size: 16px;
    &:hover {
      font-size: 16px;
      line-height: 16.5px;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    line-height: 17.5px;
    font-size: 14px;
    &:hover {
      font-size: 14px;
      line-height: 16.5px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    line-height: 15.5px;
    font-size: 14px;
    text-align: left;
    margin-left: 22%;
    margin-top: -5%;
    &:hover {
      font-size: 14px;
      line-height: 14.5px;
    }
  }
`;
export const FeatureImageWrapper = styled.div`
  color: rgba(14, 59, 114, 1);
`;
export const StyledCardContainer = styled.div`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 30px 52px -2px #5f5f5f;
  }
  &:hover ${FImage} {
    filter: brightness(100%);
  }
  &:not(:hover)${FImage} {
    filter: grayscale(35%);
  }
  &:not(:hover) ${FImage} {
    filter: brightness(100%);
  }

  &:not(:hover) ${FeatureImageWrapper} {
    opacity: 0.5;
    filter: grayscale(80%);
  }
  &:not(:hover) ${FeatureData1} {
    filter: brightness(35%);
  }
  &:hover ${ResponsiveImage} {
    width: 83.4px;
    height: 83.4px;
  }
  &:not(:hover) ${FeatureName} {
    color: rgba(14, 59, 114, 0.35);
  }
  &:hover ${FeatureName} {
    font-size: 24px;
    @media only screen and (min-width: 811px) and (max-width: 1080px) {
      font-size: 16px;
      &:hover {
        font-size: 16px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 810px) {
      font-size: 14px;
      &:hover {
        font-size: 14px;
      }
    }
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    &:hover ${ResponsiveImage} {
      width: 59px;
      height: 59px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 810px) {
    &:hover ${ResponsiveImage} {
      width: 55px;
      height: 55px;
    }
  }
`;
export const MainContainer=styled.div`
  height:100vh;
  overflow:hidden;
`;
export const StyledInnerCard = styled.div`
  background: rgba(255, 255, 255, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.31);
  width: 283px;
  height: 180.63px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
 
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 283px;
    height: 150.63px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 235.45px;
    height: 150.63px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 204.31px;
    height: 174.93px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 214px;
    height: 117px;
    display: flex;
    flex-direction: row;
  }
`;
