import styled from "styled-components";
import { devices } from "../../theme/devices";

export const StyledInfo = styled.div`
  margin: 0%;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-image: url(../back5.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: scroll;
  justify-content: center;
  background-position: center;

  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  }
`;
export const StyledDiv = styled.div`
  align-items: center;
`;
export const StyledNavbar = styled.div`
  margin: 2%;
`;
export const NavBarLogo = styled.img`
  width: 89px;
  height: 85px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 80px;
    height: 76px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 74px;
    height: 70px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 68px;
    height: 65px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 44px;
    height: 42px;
  }
`;
export const CrossIcon = styled.div`
  float: right;
  margin: 2% 2% 0 0;
`;
export const CrossImg = styled.img`
  width: 44px;
  height: 44px;
  cursor: pointer;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 35px;
    height: 35px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 35px;
    height: 35px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 24px;
    height: 24px;
  }
`;
export const StyledQuestions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
  margin: 2% 0;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    min-height: 75vh;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    min-height: 75vh;
  }

  @media only screen and (min-width: 641px) and (max-width: 810px) {
    min-height: 85vh;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    min-height: 65vh;
  }
`;
export const QuestionHeading = styled.div`
  font-size: 36px;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 20px;
  color: #003861;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const QuestionInput = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align:center;
`;
export const InputField = styled.div`
  margin: 11% 0 0 0;
  display: flex;
  text-align:center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ArrowIcon = styled.div`
  justify-content: space-between;

  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @media only screen and (min-width: 641px) and (max-width: 810px) {
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    top: 42%;
    padding: 0 2px;
  }
`;

export const OptionsNumber = styled.div``;

export const Icon1 = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 33.5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  display: flex;
  border: 1px solid #cecece;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 39px;
    height: 39px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 39px;
    height: 39px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 39px;
    height: 39px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 25px;
    height: 25px;
  }
`;
export const Icon2 = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 33.5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  display: flex;
  position: fixed;
  top: 50%;
  margin-left: 93%;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 39px;
    height: 39px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 39px;
    height: 39px;
    margin-left: 86%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 39px;
    height: 39px;
    margin-left: 86%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 25px;
    height: 25px;
  }
`;

export const Button = styled.button`
  margin: 10% 0;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  color: white;
  border-radius: 9px;
  border: 1.5px solid #003861;
  background: ${(props) => (props.disabled ? "gray" : "#0e3b72")};
  width: 463px;
  height: 81px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  &:hover {
    border-radius: 9px;
    border: 1.5px solid #249b9e;
    background: ${(props) => (props.disabled ? " #0E3B72" : "#249B9E")};
  }
  @media${devices.xxl} {
    width: 381.501px;
    height: 86.325px;
  }
  @media${devices.md} {
    width: 375.02px;
    height: 84.882px;
  }
  @media${devices.sm} {
    width: 235.797px;
    height: 52.516px;
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 273px;
    height: 49px;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredRow = styled.div`
  display: flex;
`;

export const CenteredColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const OtherButton = styled.div`
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  color: white;
  margin:10px 5px;
  width: 150px;
  border-radius: 9px;
  border: 1.5px solid #003861;
  background: ${(props) => (props.disabled ? "gray" : "#0e3b72")};
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align:center;
  padding:10px 20px;
  &:hover {
    border-radius: 9px;
    border: 1.5px solid #249b9e;
    background: ${(props) => (props.disabled ? " #0E3B72" : "#249B9E")};
  }
  @media${devices.sm} {
    width: 235.797px;
    height: 52.516px;
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 273px;
    height: 49px;
  }
`;
