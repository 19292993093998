import React, { useEffect, useState } from "react";
import { Button, Form, Col, Container, Row } from "react-bootstrap";
import styles from "./CicilServices.module.css";
import ServiceImg from "./../Assets/adminPanel/cicilServices/Services.png";
import DisabilyImg from "./../Assets/adminPanel/cicilServices/Disabilities.png";
import CommonLove from "./../Assets/adminPanel/cicilServices/CommonLove.png";
import EmploymentServices from "./../Assets/adminPanel/cicilServices/EmploymentSer.png";
import OtherImg from "./../Assets/adminPanel/cicilServices/Other.png";
import Chart from "../../AdminPanel/AdminComponents/chart";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TableData from "../AdminComponents/volunteeringData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx"; // Import xlsx library
import { ROOT_URL } from "../../../utils/variable";

function CICICLServices() {
    const navigate = useNavigate();

    const [serviceData, setServiceData] = useState([]);
    const [serviceDescription, setServiceDescription] = useState([]);
    const [disablities, setDisabilities] = useState([]);
    const [primaryDisablities, setPrimaryDisablities] = useState([]);
    const [primaryDisablitiesDesc, setPrimaryDisablitiesDesc] = useState([]);
    const [lgbtq, setlgbtq] = useState([]);
    const [employed, setEmployed] = useState([]);
    const [volunteer, setVolunter] = useState([]);
    const [aditionalVolunteerData, setaditionalVolunteerData] = useState([]);
    const [additionalInfoPercentage, setadditionalInfoPercentage] = useState([]);
    const [additionalInfoEmails, setadditionalInfoEmails] = useState([]);
    const [hearAbouts, sethearAbouts] = useState([]);
    const [downloadData, setDownloadData] = useState([]);

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchMode, setSearchMode] = useState(true); // Use searchMode to toggle functionality

    const [showServiceChart, setshowServiceChart] = useState(false);
    const toggleServiceChartWithDelay = () => {
        setTimeout(() => {
            setshowServiceChart(!showServiceChart);
        }, 300);
    };

    const [showDisablityChart, setshowDisablityChart] = useState(false);
    const toggleDisablityChartWithDelay = () => {
        setTimeout(() => {
            setshowDisablityChart(!showDisablityChart);
        }, 300);
    };

    const [showLgbtqChart, setshowLgbtqChart] = useState(false);
    const toggleLgbtqChartWithDelay = () => {
        setTimeout(() => {
            setshowLgbtqChart(!showLgbtqChart);
        }, 300);
    };
    const [showemployedChart, setshowemployedChart] = useState(false);
    const toggleEmployeedChartWithDelay = () => {
        setTimeout(() => {
            setshowemployedChart(!showemployedChart);
        }, 300);
    };

    const [showadditionaInfoChart, setadditionalInfoChart] = useState(false);
    const toggleadditionalInfoChart = () => {
        setTimeout(() => {
            setadditionalInfoChart(!showadditionaInfoChart);
        }, 300);
    };
    const PrintPage = (e) => {
        e.preventDefault();
        window.print();
    };

    useEffect(() => {
        const getData = async () => {
            axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
                "Token"
            )}`;
            axios.defaults.headers.post["Content-Type"] = "application/json";
            console.log("token", localStorage.getItem("Token"));
            //Send get request and store data
            try {
                console.time();
                const response = await axios.get(
                    `${ROOT_URL}/cicilService/report`
                );
                console.timeEnd();
                if (response.data.status === "error") {
                    console.log("Error in the data:", response.data);
                    //console.log("Response Status:",response.data.status)
                } else {
                    console.log("Original Response:", response.data);
                    dataHandler(response?.data?.data);
                    setDownloadData(response?.data?.data);
                    //console.log("Response:",response.data.data.servicePercentages)
                }
            } catch (error) {
                if (error && error.response && error.response.status === 401) {
                    //console.log("401 error");
                    navigate("/admin-login"); // Navigate to your error page
                }
            }
        };
        getData();
    }, [navigate]);

    const Backbtn = (e) => {
        e.preventDefault();
        navigate(-1);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSearchMode(!searchMode);
        if (searchMode) {
            try {
                const response = await axios.get(
                    `${ROOT_URL}/cicilService/report`,
                    {
                        params: {
                            start_date: fromDate,
                            end_date: toDate,
                        },
                    }
                );

                if (response.data.status === "error") {
                    console.log("Error in the data:", response.data);
                    //console.log("Response Status:",response.data.status)
                } else {
                    console.log("Response", response.data);
                    dataHandler(response?.data?.data);
                    setDownloadData(response?.data?.data);
                }
            } catch (error) {
                if (error && error.response && error.response.status === 401) {
                    //console.log("401 error");
                    navigate("/admin-login"); // Navigate to your error page
                }
            }
        } else {
            setFromDate("");
            setToDate("");
            window.location.reload();
        }
    };
    const dataHandler = (response) => {
        setServiceData(response?.servicePercentages);
        setServiceDescription(response?.serviceDesc);
        setDisabilities(response?.disabilityPercentages);
        setPrimaryDisablities(response?.primaryDisabilityPercentages);
        setPrimaryDisablitiesDesc(response?.primarydisabilityDesc);
        setlgbtq(response?.lgbtqPercentages);
        setEmployed(response?.employedPercentages);
        setVolunter(response?.volunteerPercentages);
        setaditionalVolunteerData(response?.volunteering_data);
        setadditionalInfoPercentage(response?.additionalInfoPercentages);
        sethearAbouts(response?.hear_abouts);
        setadditionalInfoEmails(response?.additional_info_emails);
    };

    // Helper function to add a sheet to the workbook
    const addSheetToWorkbook = (workbook, sheetName, dataObject) => {
        console.log("Sheet Name:", sheetName);
        const dataArray = Object.entries(dataObject).map(([key, value]) => ({
            [sheetName]: key,
            Percentage: value,
        }));
        const worksheet = XLSX.utils.json_to_sheet(dataArray); // Convert the data to a worksheet
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Append the worksheet to the workbook
    };

    // Helper function to add array-based data to a new sheet
    const addArraySheetToWorkbook = (workbook, sheetName, dataArray) => {
        console.log("Sheet Name:", sheetName);
        const worksheet = XLSX.utils.json_to_sheet(
            dataArray.map((item, index) => ({
                Index: index,
                Value: item,
            }))
        );
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    };

    // Helper function to add complex array-based data to a new sheet
    const addComplexArraySheetToWorkbook = (workbook, sheetName, dataArray) => {
        console.log("Sheet Name:", sheetName);
        const worksheet = XLSX.utils.json_to_sheet(dataArray);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    };

    const downloadDataFunc = (e) => {
        e.preventDefault();
        console.log("Data in download Funciton:", downloadData);
        const workbook = XLSX.utils.book_new(); // Create a new workbook

        // Add each category as a new sheet in the workbook
        addSheetToWorkbook(
            workbook,
            "Service Percentages",
            downloadData.servicePercentages
        );
        addSheetToWorkbook(
            workbook,
            "Disabilities Percentages",
            downloadData.disabilityPercentages
        );
        addSheetToWorkbook(
            workbook,
            "Primary Disabilities",
            downloadData.primaryDisabilityPercentages
        );
        addSheetToWorkbook(
            workbook,
            "LGBTQ Percentage",
            downloadData.lgbtqPercentages
        );
        addSheetToWorkbook(
            workbook,
            "Volunteer Percentage",
            downloadData.volunteerPercentages
        );
        addSheetToWorkbook(
            workbook,
            "Employed Percentage",
            downloadData.employedPercentages
        );
        addSheetToWorkbook(
            workbook,
            "Additional Info Percentage",
            downloadData.additionalInfoPercentages
        );

        // Add array-based sheets
        addArraySheetToWorkbook(
            workbook,
            "Service Description",
            downloadData.serviceDesc
        );
        addArraySheetToWorkbook(
            workbook,
            "Primary Disability Description",
            downloadData.primarydisabilityDesc
        );
        addArraySheetToWorkbook(
            workbook,
            "Additional Info Email",
            downloadData.additional_info_emails
        );

        // Add complex array-based sheets (with objects)
        addComplexArraySheetToWorkbook(
            workbook,
            "Volunteering Information",
            downloadData.volunteering_data
        );

        // Add other array-based sheets
        addArraySheetToWorkbook(workbook, "Hear About", downloadData.hear_abouts);

        // Write and download the workbook as an Excel file
        XLSX.writeFile(workbook, "CICIL Services Data.xlsx"); // Name of the file to be downloaded
    };

    return (
        <div id="cicilServices">
            <div className={`${styles.container} container-fluid`}>
                <div className={`d-flex justify-content-between p-3`}>
                    <div>
                        <img
                            src="./../Assets/adminPanel/login/image 20.png"
                            alt="Logo"
                            className={styles.logo}
                        />
                    </div>
                    <div className="p-3">
                        <Button
                            variant=""
                            type="submit"
                            className={styles.logoutButton}
                            onClick={Backbtn}
                        >
                            BACK
                            <span style={{ fontSize: "18px" }}>{"→"}</span>
                        </Button>
                    </div>
                </div>
                <Container className={`pb-5 ${styles.toggleContainer}`}>
                    <Form method="POST" onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-center">
                            {" "}
                            {/* Center the content horizontally */}
                            <Row className="justify-content-center">
                                {" "}
                                {/* Center the row horizontally for all screen sizes */}
                                <Col xs="auto" className="text-center">
                                    {" "}
                                    {/* Use xs="auto" to center the items horizontally for all screen sizes */}
                                    <div className="form-group input-group input-daterange">
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(new Date(date))}
                                            placeholderText="From"
                                            id="datepicker1"
                                            type="date"
                                            name="from_date"
                                            className="p-2 date"
                                            maxDate={
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate() + 1
                                                )
                                            }
                                            dateFormat="dd-MM-yyyy"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col xs="auto" className="text-center">
                                    <div className="form-group input-group input-daterange">
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(new Date(date))}
                                            placeholderText="To"
                                            id="datepicker2"
                                            type="date"
                                            className="p-2 date"
                                            maxDate={
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate() + 1
                                                )
                                            }
                                            dateFormat="dd-MM-yyyy"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col xs="auto" className="text-center">
                                    <Button
                                        type="submit"
                                        variant="year"
                                        className={`${styles.searchBtn}`}
                                    >
                                        {searchMode ? "Search" : "Reset"}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Container>
                <div className={`row justify-content-center`}>
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleServiceChartWithDelay}>
                            <div className="box-left">
                                <img
                                    className="mt-3 mx-2"
                                    src={ServiceImg}
                                    alt="gradeimg"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showServiceChart ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px",
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Services</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure
                    className={`survey-table-fade ${showServiceChart ? "show" : ""}`}
                >
                    {showServiceChart && (
                        <Chart
                            chartData={serviceData}
                            fullData={serviceDescription}
                            table="true"
                            tableDescription="Other"
                            chartTitle="Services"
                        />
                    )}
                </figure>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleDisablityChartWithDelay}>
                            <div className="box-left">
                                <img
                                    className="mt-3 mx-2"
                                    src={DisabilyImg}
                                    alt="gradeimg"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showDisablityChart ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px",
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Disabilities</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure
                    className={`survey-table-fade ${showDisablityChart ? "show" : ""}`}
                >
                    {showDisablityChart && (
                        <Chart
                            chartData={disablities}
                            table="false"
                            chartTitle="Disabilities"
                        />
                    )}
                    {showDisablityChart && (
                        <Chart
                            chartData={primaryDisablities}
                            fullData={primaryDisablitiesDesc}
                            table="true"
                            tableDescription="Other Disabilities"
                            chartTitle="Primary Disabilities"
                        />
                    )}
                </figure>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleLgbtqChartWithDelay}>
                            <div className="box-left">
                                <img
                                    className="mt-3 mx-2"
                                    src={CommonLove}
                                    alt="gradeimg"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showLgbtqChart ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px",
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Common Love DSM</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showLgbtqChart ? "show" : ""}`}>
                    {showLgbtqChart && (
                        <Chart
                            chartData={lgbtq}
                            table="false"
                            chartTitle="LGBTQ Community"
                        />
                    )}
                    {showLgbtqChart && (
                        <Chart
                            chartData={volunteer}
                            table="false"
                            chartTitle="Volunteering For Events"
                        />
                    )}
                    {showLgbtqChart && <TableData fullData={aditionalVolunteerData} />}
                </figure>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleEmployeedChartWithDelay}>
                            <div className="box-left">
                                <img
                                    className="mt-3 mx-2"
                                    src={EmploymentServices}
                                    alt="gradeimg"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showemployedChart ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px",
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>
                                    Employment Services
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure
                    className={`survey-table-fade ${showemployedChart ? "show" : ""}`}
                >
                    {showemployedChart && (
                        <Chart chartData={employed} table="false" chartTitle="Employment" />
                    )}
                </figure>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleadditionalInfoChart}>
                            <div className="box-left">
                                <img
                                    className="mt-3 mx-2"
                                    src={OtherImg}
                                    alt="gradeimg"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showadditionaInfoChart ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px",
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Other</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure
                    className={`survey-table-fade ${showadditionaInfoChart ? "show" : ""
                        }`}
                >
                    <h4 className="text-center pt-2">How did you hear about us?</h4>
                    {showadditionaInfoChart && (
                        <Chart
                            chartData=""
                            fullData={hearAbouts}
                            table="true"
                            tableDescription="Other"
                        />
                    )}
                    {showadditionaInfoChart && (
                        <Chart
                            chartData={additionalInfoPercentage}
                            fullData={additionalInfoEmails}
                            tableDescription="Emails"
                            table="true"
                            chartTitle="Additional Information"
                        />
                    )}
                </figure>
                <div>
                    <div>
                        <div class="container-fluid">
                            <Row className="py-4">
                                <Col xs={12} className="d-flex justify-content-end p-3">
                                    <Button
                                        className={`${styles.downloadbtn} mx-3`}
                                        onClick={downloadDataFunc}
                                    >
                                        Download Data
                                    </Button>
                                    <Button
                                        className={`${styles.downloadbtn}`}
                                        onClick={PrintPage}
                                    >
                                        Print This Page
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CICICLServices;
