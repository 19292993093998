import { BsEye, BsPerson } from "react-icons/bs";
import { FaWheelchair } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import {
  MdDiversity2,
  MdOutlineCall,
  MdOutlineDesignServices,
  MdOutlineHearing,
  MdOutlineHolidayVillage,
  MdOutlineHub,
  MdOutlineInterests,
  MdOutlineInventory,
  MdOutlinePersonalInjury,
  MdOutlinePsychology,
  MdOutlinePsychologyAlt,
} from "react-icons/md";
export const questions = [
  {
    id: 0,
    service: "CICIL Services",
    text: "What service are you here for?",
    type: "MCQSIcon",
    status: "yes",
    field: "service",
    isFirst: true,
    options: [
      {
        label: "TSA PreCheck",
        value: "TSA PreCheck",
        icon: <MdOutlineInventory style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Drop-In Center",
        value: "Drop-In Center",
        icon: (
          <MdOutlineHolidayVillage style={{ width: "100%", height: "100%" }} />
        ),
      },
      {
        label: "Employment Services",
        value: "Employment Services",
        icon: <MdDiversity2 style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Common Love DSM",
        value: "Common Love DSM",
        icon: <MdOutlineInterests style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "WALS(Work and Life Skills)",
        value: "WALS(Work and Life Skills)",
        icon: <MdOutlineHub style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Other-Please specify",
        value: "Other",
        icon: (
          <MdOutlineDesignServices style={{ width: "100%", height: "100%" }} />
        ),
      },
    ],
    nextQuestionId: "1",
  },
  {
    id: 1,
    service: "CICIL Services",
    text: "Do you identify as having a disability?",
    type: "MCQS",
    status: "yes",
    field: "disability",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "Unsure", value: "Unsure" },
      { label: "Prefer not to disclose", value: "Prefer not to disclose" },
    ],
    nextQuestionId: "2",
  },

  {
    id: 2,
    service: "CICIL Services",
    text: "What do you consider your primary disability?",
    type: "MCQSIconDetail",
    field: "primary_disability",
    isSubmitButton: false,
    options: [
      {
        label: "Physical",
        value: "Physical",
        icon: <FaWheelchair style={{ width: "100%", height: "100%" }} />,
        detail:
          "(Ex. Amputation, Arthritis, Back Injury, Cardiac, Cerebral Palsy, Multiple Sclerosis, Muscular Dystrophy, Paraplegia, etc.)",
      },
      {
        label: "Cognitive",
        value: "Cognitive",
        icon: <MdOutlinePsychology style={{ width: "100%", height: "100%" }} />,
        detail:
          "(Ex. Developmental, Epilepsy, Learning Disability, Neurological Disorder etc.)",
      },
      {
        label: "Mental/Emotional",
        value: "Mental/Emotional",
        icon: (
          <MdOutlinePsychologyAlt style={{ width: "100%", height: "100%" }} />
        ),
        detail:
          "(Ex. Alzheimer's, Autism, Down Syndrome, Emotional/Behavioral Disabilities, Intellectual Disability, Mental Health Diagnosis, etc.)",
      },
      {
        label: "Hearing",
        value: "Hearing",
        icon: <MdOutlineHearing style={{ width: "100%", height: "100%" }} />,
        detail: "(Deafness, Hard of Hearing, Hearing Loss)",
      },
      {
        label: "Vision",
        value: "Vision",
        icon: <BsEye style={{ width: "100%", height: "100%" }} />,
        detail: "(Vision Impairment, Blindness)",
      },
      {
        label: "Other-Please specify",
        value: "Other",
        icon: (
          <MdOutlinePersonalInjury style={{ width: "100%", height: "100%" }} />
        ),
      },
    ],
    nextQuestionId: "3",
  },
];

export const commonLove = [
  {
    id: 3,
    service: "CICIL Services",
    type: "MCQS",
    text: "Do you identify as part of the LGBTQ community?",
    field: "is_lgbtq",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "4",
  },

  {
    id: 4,
    service: "CICIL Services",
    type: "MCQS",
    text: "Would you be interested in volunteering for events?",
    field: "is_volunteer",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "5",
  },

  {
    id: 5,
    service: "CICIL Services",
    text: "Contact Info",
    type: "text",
    options: [
      {
        name: "name",
        label: "Name",
        value: "Name",
        icon: <BsPerson style={{ width: "100%", height: "100%" }} />,
        placeholder: "Name",
      },
      {
        name: "email",
        label: "Email",
        value: "Email",
        icon: <GoMail style={{ width: "100%", height: "100%" }} />,
        placeholder: "email",
      },
      {
        name: "phone",
        label: "Phone",
        value: "Phone",
        icon: <MdOutlineCall style={{ width: "100%", height: "100%" }} />,
        placeholder: "Phone",
      },
    ],
    nextQuestionId: "6",
    isLast: true,
  },
];

export const employmentService = [
  {
    id: 3,
    service: "CICIL Services",
    text: "Are you currently employed?",
    type: "MCQS",
    value: "Employee",
    field: "is_employed",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "4",
    isLast: true,
    isSubmitButton: true,
  },
];

export const others = [
  {
    id: 3,
    service: "CICIL Services",
    text: "How did you hear about us?",
    type: "text",
    options: [
      {
        label: "Write your answer",
        vlaue: "Write your answer",
        placeholder: "Your answer",
        name: "source",
      },
    ],
    nextQuestionId: "4",
  },
  {
    id: 4,
    service: "CICIL Services",
    text: "Would you like additional information about services at CICIL?",
    type: "MCQS",
    field: "additional_info",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "5",
    isLast: true,
  },
];
