import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  PopupContainer,
  CloseIcon,
  InputContainer,
  Input1,
  Backdrop,
  ButtonContainer,
  Button1,
  Label,
} from "./style";

const InputPopup = ({ onClose, handleInputChange, handleSubmit }) => {
  return ReactDOM.createPortal(
    <>
      <Backdrop />
      <PopupContainer>
        <CloseIcon onClick={onClose}>&times;</CloseIcon>
        <InputContainer>
          <Label htmlFor="inputField">Input Field:</Label>
          <Input1
            type="text"
            id="inputField"
            onChange={handleInputChange}
            required
            placeholder="Enter text"
          />
        </InputContainer>
        <ButtonContainer>
          <Button1 type="button" onClick={handleSubmit}>
            Submit
          </Button1>
        </ButtonContainer>
      </PopupContainer>
    </>,
    document.body
  );
};

export default InputPopup;
