import React from "react";
import { CheckmarkIcon, InputContainer, StyledInput, StyledP } from "./styles";

function Input1({ field, content, value, checked, handleClick }) {
  return (
    <InputContainer onClick={() => handleClick(field, value)}>
      <CheckmarkIcon isVisible={checked}>&#10003;</CheckmarkIcon>
      <StyledInput checked={checked}>
        <StyledP checked={checked}>{content}</StyledP>
      </StyledInput>
    </InputContainer>
  );
}

export default Input1;
