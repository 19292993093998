import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import dayjs from "dayjs";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledInfo,
  StyledDiv,
  StyledNavbar,
  NavBarLogo,
  CrossIcon,
  CrossImg,
  StyledQuestions,
  QuestionHeading,
  QuestionInput,
  InputField,
  OptionsNumber,
  ArrowIcon,
  Icon1,
  Icon2,
  CenteredContainer,
  CenteredColumn,
  CenteredRow,
  OtherButton,
  Button,
  ErrorMsg,
} from "./style";
import {
  questions,
  commonLove,
  employmentService,
  others,
  commonQuestion,
} from "../data/cicilData";
import { useNavigate } from "react-router-dom";
import Popup from "../formPopUp/index";
import OptionInput from "../Input";
import Input1 from "../Input1";
import Input3 from "../Input3";
import AdditionalInput from "../AdditionalInput";
import InputPopup from "../InputPopUp";
import Input2 from "../Input2";
import { GoMail } from "react-icons/go";
import { ROOT_URL } from "../../utils/variable";
import StyledErrorComponent from "../ErrorMessage";
import { CiVault } from "react-icons/ci";

const CicilServices = () => {
  const [state, setState] = useState({});
  const [currentServiceQuestionIndex, setCurrentServiceQuestionIndex] =
    useState(0);
  const [listOfQuestions, setListOfQuestions] = useState([...questions]);
  const [category, setCategory] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [optionValue, setOptionValue] = useState({});
  const [otherField, setOtherField] = useState(null);
  const [otherFieldValue, setOtherFieldValue] = useState(null);
  const [isOtherOpen, setIsOtherOpen] = useState(false);
  const [randomDigit, setRandomDigit] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitButton, setIsSubmitButton] = useState(false);
  const [isNextButton, setIsNextButton] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [Msg, setMessage] = useState("");
  const [MsgDisable, setMsgDisable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (category === "Common Love DSM") {
      setListOfQuestions([...questions, ...commonLove]);
    } else if (category === "Employment Services") {
      setListOfQuestions([...questions, ...employmentService]);
    } else if (category === "Other") {
      setListOfQuestions([...questions, ...others]);
    } else {
      setListOfQuestions([...questions]);
    }
    setState({ service: category });
    setOptionValue({ service: category });
  }, [category]);

  const handlePrevClick = () => {
    setMsgDisable(false);
    setIsEmail(false);
    setIsSubmitButton(false);
    const prevQuestionIndex =
      isDisable && currentServiceQuestionIndex === 3
        ? currentServiceQuestionIndex - 2
        : currentServiceQuestionIndex - 1;
    if (prevQuestionIndex >= 0) {
      setCurrentServiceQuestionIndex(prevQuestionIndex);
    }
    setIsNextButton(false);
  };

  const handleNextClick = () => {
    setIsSubmitButton(false);
    console.log('Category is:',category);
    setMsgDisable(false);
    const isSelectionValid = validateSelection();
    // If the selection is valid, proceed to the next page
    if (state.disability !== "Yes" && state.disability !== undefined) {
      //setIsSubmitButton(true);
    }
    if (
      state.primary_disability !== undefined &&
      state.primary_disability !== ""
    ) {
      //setIsSubmitButton(true);
    }
    if (!isSelectionValid) {
      setMessage("Please select an option to proceed.");
      setMsgDisable(true);

      // // Show the message for 10 seconds
      // setTimeout(() => {
      //   setMessage(''); // Clear the message after 10 seconds
      //   setMsgDisable(false); // Enable the message after 10 seconds if needed
      // }, 2000); // 20000 milliseconds = 20 seconds

      return;
    } else if (state.additional_info === "Yes") {
      setIsEmail(true);
    }
    const nextQuestionIndex =
      isDisable && currentServiceQuestionIndex === 1
        ? currentServiceQuestionIndex + 2
        : currentServiceQuestionIndex + 1;

    if (nextQuestionIndex < listOfQuestions.length) {
      setCurrentServiceQuestionIndex(nextQuestionIndex);
    }
    if (
      category !== "Common Love DSM" &&
      category !== "Employment Services" &&
      category !== "Other" &&
      currentServiceQuestionIndex === 1
    ) {
      setIsNextButton(true);
    }
    if (nextQuestionIndex === listOfQuestions.length - 1) {
      setIsNextButton(true);
    }
  };

  const handleCrossIconClick = () => {
    navigate("/");
  };
  const currentQuestion = listOfQuestions.find(
    (question) => question.id === currentServiceQuestionIndex
  );
  const handleMultiClick = (field, value) => {
    setIsNextButton(false);
    if (field === "service") {
      if (state[field] === value) {
        setCategory(null);
        const othersValue = `${field}_desc`;
        setState({ ...state, [field]: null, [othersValue]: "" });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setCategory(value);
        const othersValue = `${field}_desc`;
        setState({ ...state, [field]: value, [othersValue]: "" });
        setOptionValue({ ...optionValue, [field]: value });
        if (value === "Other") {
          setIsNextButton(true);
          setOtherField(`${field}_desc`);
          setIsOtherOpen(true);
        }
      }
    } else if (field === "is_employed" && value === "Yes") {
      setIsNextButton(true);
      if (state[field] === value) {
        setState({ ...state, [field]: null, is_employed: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
      }
    } else if (field === "is_employed" && value === "No") {
      setIsNextButton(true);
      if (state[field] === value) {
        setState({ ...state, [field]: null, is_employed: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value, is_employed: null });
        setOptionValue({ ...optionValue, [field]: value });
      }
    } else if (field === "additional_info" && value === "Yes") {
      setIsNextButton(true);
      if (state[field] === value) {
        setIsEmail(false);
        setState({ ...state, [field]: null, info_email: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setIsEmail(true);
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
      }
    } else if (field === "additional_info" && value === "No") {
      setIsNextButton(true);
      if (state[field] === value) {
        setState({ ...state, [field]: null, info_email: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value, info_email: null });
        setOptionValue({ ...optionValue, [field]: value });
      }
      setIsEmail(false);
    } else if (field === "disability" && value !== "Yes") {
      setIsSubmitButton(false);
      if (state[field] === value) {
        setIsNextButton(false);
        setIsDisable(false);
        setState({
          ...state,
          [field]: null,
          primary_disability: null,
          primary_disability_desc: null,
        });
        setOptionValue({
          ...optionValue,
          [field]: null,
          primary_disability: null,
        });
      } else {
        if (
          category !== "Common Love DSM" &&
          category !== "Employment Services" &&
          category !== "Other"
        ) {
          setIsSubmitButton(true);
          setIsNextButton(true);
        }
        setIsDisable(true);
        setState({
          ...state,
          [field]: value,
          primary_disability: null,
          primary_disability_desc: null,
        });
        setOptionValue({
          ...optionValue,
          [field]: value,
          primary_disability: null,
        });
      }
    } else if (field === "disability" && value === "Yes") {
      setIsSubmitButton(false);
      setIsNextButton(false);
      setIsDisable(false);
      if (state[field] === value) {
        setState({ ...state, [field]: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
      }
    } else if (field === "is_volunteer" && value === "No") {
      if (state[field] === value) {
        setIsSubmitButton(false);
        setIsNextButton(false);
        setState({
          ...state,
          [field]: null,
          phone: null,
          email: null,
          name: null,
        });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setIsSubmitButton(true);
        setIsNextButton(true);
        setState({
          ...state,
          [field]: value,
          phone: null,
          email: null,
          name: null,
        });
        setOptionValue({ ...optionValue, [field]: value });
      }
    } else if (field === "is_volunteer" && value === "Yes") {
      setIsSubmitButton(false);
      setIsNextButton(false);
      if (state[field] === value) {
        setState({
          ...state,
          [field]: null,
          phone: null,
          email: null,
          name: null,
        });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({
          ...state,
          [field]: value,
        });
        setOptionValue({ ...optionValue, [field]: value });
      }
    }
    else if (field === "is_lgbtq") {
      console.log('Field Name:',field)
      setIsSubmitButton(false);
      setIsNextButton(false);
      if (state[field] === value) {
        setState({
          ...state,
          [field]: null,
          phone: null,
          email: null,
          name: null,
        });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({
          ...state,
          [field]: value,
        });
        setOptionValue({ ...optionValue, [field]: value });
      }
    }  
    else if (field === "primary_disability") {
      if (state[field] === value) {
        setIsSubmitButton(false);
        setState({ ...state, [field]: null, primary_disability_desc: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        if (
          category !== "Common Love DSM" &&
          category !== "Employment Services" &&
          category !== "Other"
        ) {
          setIsSubmitButton(true);
        }
        if (
          category === "TSA PreCheck" ||
          category === "Drop-In Center" ||
          category === "WALS(Work and Life Skills)"
        ) {
          setIsNextButton(true);
        }

        if (value === "Other") {
          setIsNextButton(true); //True means do not show the button
          setOtherField(`${field}_desc`);
          setIsOtherOpen(true);
          setState({ ...state, [field]: value });
          setOptionValue({ ...optionValue, [field]: value });
        } else {
          setState({ ...state, [field]: value, primary_disability_desc: null });
          setOptionValue({ ...optionValue, [field]: value });
        }
      }
    } else if (value === "Other") {
      if (state[field] === value) {
        const descField = `${field}_desc`;
        setState({ ...state, [field]: null, [descField]: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setIsNextButton(true);
        setOtherField(`${field}_desc`);
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
        setIsOtherOpen(true);
      }
    } else {
      if (state[field] === value) {
        setState({ ...state, [field]: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
      }
    }
  };

  function validatePhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the cleaned phone number has exactly 10 digits and contains only numbers
    if (cleanPhoneNumber.length === 10 && /^\d+$/.test(cleanPhoneNumber)) {
      return true;
    }

    return false;
  }
  function hasOnlyFourDigits(str) {
    // Use a regular expression to match exactly four digits
    const pattern = /^\d{4}$/;
    return pattern.test(str);
  }
  const handleChange = (e) => {
    if (e.target.name === "time_in" || e.target.name === "time_out") {
      const inputTime = e.target.value;
      const dayjsDate = dayjs();
      const formattedTime = dayjsDate
        .set("hour", inputTime.split(":")[0])
        .set("minute", inputTime.split(":")[1]);
      const formattedOutput = formattedTime.format("YYYY-MM-DD HH:mm:ss");
      if (formattedOutput !== "Invalid Date") {
        setState({ ...state, [e.target.name]: formattedOutput });
      } else {
        setState({ ...state, [e.target.name]: null });
      }
    } else if (e.target.name === "staff_phone") {
      const phoneNumber = e.target.value;
      const validatedPhone = validatePhoneNumber(phoneNumber);
      if (validatedPhone) {
        setState({ ...state, [e.target.name]: phoneNumber });
      } else {
        setState({ ...state, [e.target.name]: null });
      }
    } else if (e.target.name === "code") {
      const isValidCode = hasOnlyFourDigits(e.target.value);
      if (isValidCode) {
        setState({ ...state, [e.target.name]: e.target.value });
      } else {
        setState({ ...state, [e.target.name]: null });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  //   const handleSubmit = () => {
  //     const url = `${ROOT_URL}${URL_LIST[serviceName]}`;
  //     axios.post(url, state).then((res) => {
  //       navigate("/submission");
  //     });
  //   };
  const handleOtherChange = (e, field, value) => {
    console.log(`Field ${field} and value ${value} and category ${category}`);
    // setOtherFieldValue({ [otherField]: e.target.value });
    if (e.target.value === "" || e.target.value === null) {
      setIsNextButton(true);
    } else if (
      category === "TSA PreCheck" ||
      category === "Drop-In Center" ||
      category === "WALS(Work and Life Skills)"
    ) {
      setIsNextButton(true);
    } else {
      setIsNextButton(false);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleOtherSubmit = () => {
    setState({ ...state, ...otherFieldValue });
    setIsOtherOpen(false);
  };

  const handleRandomConfirm = () => {
    navigate("/");
  };
  function getAllArrayValues(obj) {
    const valuesArray = Object.values(obj); // Get all values from the object
    const arrays = valuesArray.filter((value) => Array.isArray(value)); // Filter arrays from the values
    const result = [].concat.apply([], arrays); // Merge arrays into a single array
    return result;
  }
  const handleSubmit = () => {
    const url = `${ROOT_URL}/cicilServices/Store`;
    console.log("state", state);
    axios
      .post(url, state)
      .then((res) => {
        if (res.data.status === "success") {
          navigate("/submission");
        } else if (res.data.status === "error") {
          setErrorMessage(getAllArrayValues(res.data.message));
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };
  const isLastDemographicQuestion =
    currentQuestion?.service === "Demographic Information" &&
    currentQuestion?.id ===
      questions
        .filter((question) => question.service === "Demographic Information")
        .slice(-1)[0]?.id;

  const validateSelection = () => {
    if (
      currentQuestion.type === "MCQSIcon" &&
      currentQuestion.status === "yes"
    ) {
      console.log("Options:", currentQuestion.options);

      // Check if options are defined and it's an array
      if (Array.isArray(currentQuestion.options)) {
        // Assuming each option has a 'value' property
        const isAnyOptionSelected = currentQuestion.options.some(
          (option) =>
            option.value === optionValue[currentQuestion.field] ||
            (Array.isArray(optionValue[currentQuestion.field]) &&
              optionValue[currentQuestion.field].includes(option.value))
        );

        return isAnyOptionSelected;
      }
      return false; // or handle the case where the array is not defined
    } else if (
      currentQuestion.type === "MCQS" &&
      currentQuestion.status === "yes"
    ) {
      console.log("Options:", currentQuestion.options);

      // Check if options are defined and it's an array
      if (Array.isArray(currentQuestion.options)) {
        // Assuming each option has a 'value' property
        const isAnyOptionSelected = currentQuestion.options.some(
          (option) =>
            option.value === optionValue[currentQuestion.field] ||
            (Array.isArray(optionValue[currentQuestion.field]) &&
              optionValue[currentQuestion.field].includes(option.value))
        );

        return isAnyOptionSelected;
      }
      return false; // or handle the case where the array is not defined
    }
    //   else if (currentQuestion.type === "MCQSIconDetail") {
    //   console.log("Options:", currentQuestion.options);

    //   // Check if options are defined and it's an array
    //   if (Array.isArray(currentQuestion.options)) {
    //     // Assuming each option has a 'value' property
    //     const isAnyOptionSelected = currentQuestion.options.some(
    //       (option) =>
    //         option.value === optionValue[currentQuestion.field] ||
    //         (Array.isArray(optionValue[currentQuestion.field]) &&
    //           optionValue[currentQuestion.field].includes(option.value))
    //     );

    //     return isAnyOptionSelected;
    //   }
    //   return false; // or handle the case where the array is not defined
    // }
    else if (currentQuestion.type === "text") {
      // Implement text field validation if needed
      // You may want to add specific validation logic based on your requirements
      return true; // Placeholder, replace with your validation logic
    }

    // Handle other types or default to false
    return true;
  };

  return (
    <StyledInfo>
      <StyledDiv>
        <StyledNavbar>
          <NavBarLogo src="../logo1.png" />
          <CrossIcon onClick={handleCrossIconClick}>
            <CrossImg src="../cross1.png" />
          </CrossIcon>
        </StyledNavbar>

        <StyledQuestions>
          {currentQuestion && (
            <>
              <QuestionHeading>{currentQuestion.text}</QuestionHeading>
              <QuestionInput>
                {MsgDisable &&
                  Msg.length > 0 &&
                  (<StyledErrorComponent message={Msg} />)
                }
              </QuestionInput>
              <QuestionInput>
                {errorMessage &&
                  errorMessage.length > 0 &&
                  errorMessage.map((message) => {
                    return <StyledErrorComponent message={message} />;
                  })}
                <InputField>
                  {currentQuestion.options.map((option, index) => {
                    if (currentQuestion.type === "MCQSIcon") {
                      return (
                        <OptionInput
                          field={currentQuestion.field}
                          label={option.label}
                          icon={option.icon}
                          checked={
                            option.value ===
                              optionValue[currentQuestion.field] ||
                            (Array.isArray(
                              optionValue[currentQuestion.field]
                            ) &&
                              optionValue[currentQuestion.field].includes(
                                option.value
                              ))
                          }
                          value={option.value}
                          handleClick={handleMultiClick}
                        />
                      );
                    } else if (currentQuestion.type === "MCQS") {
                      return (
                        <Input1
                          field={currentQuestion.field}
                          content={option.label}
                          value={option.value}
                          handleClick={handleMultiClick}
                          checked={
                            option.value ===
                              optionValue[currentQuestion.field] ||
                            (Array.isArray(
                              optionValue[currentQuestion.field]
                            ) &&
                              optionValue[currentQuestion.field].includes(
                                option.value
                              ))
                          }
                        />
                      );
                    } else if (currentQuestion.type === "text") {
                      return (
                        <Input3
                          onChange={handleChange}
                          icon={option.icon}
                          name={option.name}
                          label={option.label}
                          placeholder={option.placeholder}
                          type={option.type}
                          error={
                            state[option.name] === null ||
                            state[option.name] === ""
                          }
                          value={state[option.name]}
                        />
                      );
                    } else if (currentQuestion.type === "MCQSIconMulti") {
                      return (
                        <OptionInput
                          field={currentQuestion.field}
                          label={option.label}
                          icon={option.icon}
                          checked={
                            currentQuestion.field === "areas_used" &&
                            optionValue?.areas_used?.length > 0 &&
                            optionValue.areas_used.includes(option.value)
                          }
                          value={option.value}
                          handleClick={handleMultiClick}
                        />
                      );
                    } else if (currentQuestion.type === "MCQSIconDetail") {
                      return (
                        <Input2
                          field={currentQuestion.field}
                          label={option.label}
                          icon={option.icon}
                          detail={option.detail}
                          checked={
                            option.value ===
                              optionValue[currentQuestion.field] ||
                            (Array.isArray(
                              optionValue[currentQuestion.field]
                            ) &&
                              optionValue[currentQuestion.field].includes(
                                option.value
                              ))
                          }
                          value={option.value}
                          handleClick={handleMultiClick}
                        />
                      );
                    }
                  })}
                  {state[currentQuestion.field] === "Yes" &&
                    currentQuestion.field === "additional_info" && (
                      <AdditionalInput
                        onChange={handleChange}
                        icon={
                          <GoMail style={{ width: "100%", height: "100%" }} />
                        }
                        name="info_email"
                        label="Email"
                        placeholder="Please Enter your Email"
                        error={
                          state.info_email === null || state.info_email === ""
                        }
                        value={state.info_email || ""} // Set the value here
                      />
                    )}
                  {currentQuestion.field === "primary_disability" &&
                    state[currentQuestion.field] === "Other" && (
                      <Input3
                        onChange={handleOtherChange}
                        name={`${currentQuestion.field}_desc`}
                        label="Please Specify"
                        placeholder="Please Enter the Value"
                        value={state[`${currentQuestion.field}_desc`]} // Set the value here
                      />
                    )}
                  {currentQuestion.field !== "primary_disability" &&
                    state[currentQuestion.field] === "Other" && (
                      <AdditionalInput
                        onChange={handleOtherChange}
                        name={`${currentQuestion.field}_desc`}
                        label="Please Specify"
                        placeholder="Please Enter the Value"
                        value={state[`${currentQuestion.field}_desc`]} // Set the value here
                      />
                    )}
                  {(isSubmitButton || currentQuestion.isLast) && (
                    <Button onClick={handleSubmit}>Submit</Button>
                  )}
                  {/* {isLastDemographicQuestion && (
                    <Button onClick={handleSubmitButton}>Submit</Button>
                  )} */}
                </InputField>
              </QuestionInput>
            </>
          )}
        </StyledQuestions>

        <ArrowIcon>
          {!currentQuestion?.isFirst && (
            <Icon1 onClick={handlePrevClick}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  cursor: "pointer",
                  color: "#003861",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Icon1>
          )}

          {(!isSubmitButton || !currentQuestion.isSubmitButton) &&
            !isNextButton && (
              <Icon2 onClick={handleNextClick}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{
                    cursor: "pointer",
                    color: "#003861",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </Icon2>
            )}
        </ArrowIcon>
      </StyledDiv>
      {isPopupOpen && randomDigit !== null && (
        <Popup
          digits={randomDigit}
          onClose={() => setIsPopupOpen(false)}
          onConfirm={handleRandomConfirm}
        />
      )}
      {/* {isOtherOpen && (
        <InputPopup
          handleInputChange={handleOtherChange}
          handleSubmit={handleOtherSubmit}
          onClose={() => setIsOtherOpen(false)}
        />
      )} */}
      <CenteredContainer>
        <CenteredRow>
          <CenteredColumn>
            {!currentQuestion?.isFirst && (
              <OtherButton type="submit" onClick={handlePrevClick}>
                Previous
              </OtherButton>
            )}
          </CenteredColumn>
          <CenteredColumn>
            {(!isSubmitButton || !currentQuestion.isSubmitButton) &&
              !isNextButton && (
                <OtherButton type="submit" onClick={handleNextClick}>
                  Next
                </OtherButton>
              )}
          </CenteredColumn>
        </CenteredRow>
      </CenteredContainer>
    </StyledInfo>
  );
};

export default CicilServices;
