import React, { useRef, useState } from "react";
import {
  CheckmarkIcon,
  InputContainer,
  RadioInput,
  StyledIcon,
  StyledInput,
  StyledP,
} from "./styles";

function OptionInput({ label, value, icon, checked, handleClick, field }) {
  return (
    <InputContainer onClick={() => handleClick(field, value)}>
      <CheckmarkIcon isVisible={checked}>&#10003;</CheckmarkIcon>
      <StyledInput type="text" checked={checked}>
        {/* <RadioInput> */}
        <StyledIcon checked={checked}>{icon}</StyledIcon>
        <StyledP checked={checked}>{label}</StyledP>
        {/* </RadioInput> */}
      </StyledInput>
    </InputContainer>
  );
}
export default OptionInput;
