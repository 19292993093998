import styled from 'styled-components';


export const PageContainer = styled.div`

  height: 100vh; 
  flex-direction: column;
  background-image: url('../back1.png') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;

    background-position: center; 
  @media only screen and (min-width:811px) and (max-width:1080px){

  }
`;
export const Image2=styled.div`
align-items: center;
justify-content: center;
display: flex;
margin-top:8%;
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-top: 2%;
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-top: 6%;
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-top: 14%;
}
`;
export const Image1=styled.img`
  width:306px;
  height:277px;
  @media only screen and (min-width:811px) and (max-width:1080px){
    width:240px;
    height:216px;
      }  
      @media only screen and (min-width:641px) and (max-width:810px){
    width:197px;
    height:177px;
      }   
      @media only screen and (min-width:320px) and (max-width:640px){
    width:131px;
    height:117px;
      }   
      `;

export const Card1=styled.div`
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
margin-top: 8%;
@media only screen and (min-width:1081px) and (max-width:1280px){
  min-height:80vh;

}
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-top:16%;
  min-height:70vh;
}
@media only screen and (min-width:811px) and (max-width:1080px){
  margin-top:16%;
  min-height:70vh;
}
@media only screen and (min-width:641px) and (max-width:810px){
  margin-top:16%;
  min-height:70vh;
  transform: translateY(50px);
}
@media only screen and (min-width:320px) and (max-width:640px){
  margin-top:16%;
  /* min-height:70vh; */
  transform: translateY(70px);
}
`;
export const Card = styled.div`
  background-color: #fff; 
  box-shadow: 0px 100px 80px 0 rgba(49, 49, 49, 0.2);
 border:1px solid rgba(35, 38, 56, 0.1);
    width:755px;
    height:648px;
    justify-content:center;
    align-items: center;
    margin-left:-17%; 
    @media only screen and (min-width:1081px) and (max-width:1280px){
        width:755px;
    height:616px;
    }
    @media only screen and (min-width:811px) and (max-width:1080px){
      width:541px;
    height:512px;
    margin-left:-19%; 
    }
    @media only screen and (min-width:641px) and (max-width:810px){
      width:476px;
    height:489px;
    margin-left:-22%; 
    }
    @media only screen and (min-width:320px) and (max-width:640px){
      width:249px;
    height:293px;
    margin-left:0%; 
    }
    `;

export const ThankYouMessage = styled.p`
  text-align: center;
  font-family: 'Roboto';
  font-weight:600;
  line-height:48px;
  color:#2B5683;
  font-size:40px;
  width:100%;
  align-items:center;
  justify-content: center;

  @media only screen and (min-width:811px) and (max-width:1080px){
    font-size: 40px;
    line-height: 43.2px;
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    font-size: 40px;
    line-height: 43.2px;
  }
  @media only screen and (min-width:641px) and (max-width:810px){
    font-size: 36px;
    line-height: 43.2px;
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    font-size: 20px;
    line-height: 21.6px;
  }
`;

export const CopyrightMessage = styled.p`
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px; 
  color: #232638; 
  font-size: 12px;
  margin-top:7%;
  @media only screen and (min-width:811px) and (max-width:1080px){
margin-top:12%;

  }
  @media only screen and (min-width:641px) and (max-width:810px){
margin-top:21%;

  }
  @media only screen and (min-width:320px) and (max-width:640px){
margin-top:35%;

  }
`;

export const Img3=styled.div`
float: left;
margin-top:-10%;
@media only screen and (min-width:1081px) and (max-width:1280px){
  width:202px;
  height:431px;
  margin-left:-4%;
} 
@media only screen and (min-width:811px) and (max-width:1080px){
  width:164px;
  height:350px;
  margin-left:-4%;
  margin-top:-25%;
} 
@media only screen and (min-width:641px) and (max-width:810px){
  width:164px;
  height:350px;
  margin-left:-4%;
  margin-top:-42%;
} 
@media only screen and (min-width:320px) and (max-width:640px){
  width:100px;
  height:214px;
  margin-left:-9%;
  margin-top:-74%;
} 
`;
export const Img4=styled.img``;
export const Button=styled.div`
align-items: center;
justify-content: center;
display: flex;

`;
export const Button1=styled.button`
align-items: center;
justify-content: center;
font-size:24px;
font-family:'Roboto';
font-weight:600;
line-height:20px;
width:335px;
height:70px;
border-radius: 9px;
color:#FFFFFF;
background-color:rgba(14, 59, 114, 1);
border: 1.5px solid rgba(0, 56, 97, 1);
cursor: pointer;
@media only screen and (min-width:811px) and (max-width:1080px){
    width:297px;
height:70px;
}
@media only screen and (min-width:641px) and (max-width:810px){
    width:262px;
height:65px;
font-size: 20px;
}
@media only screen and (min-width:320px) and (max-width:640px){
    width:161px;
height:39px;
border-radius: 6px;
font-size: 14px;

}

`;



