import { BsEye, BsPerson } from "react-icons/bs";
import { FaMale, FaWheelchair } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { CiLogin } from "react-icons/ci";
import {
  MdDiversity2,
  MdFitnessCenter,
  MdOutlineCall,
  MdOutlineCategory,
  MdOutlineDesignServices,
  MdOutlineDraw,
  MdOutlineGroups,
  MdOutlineHearing,
  MdOutlineHolidayVillage,
  MdOutlineHub,
  MdOutlineInterests,
  MdOutlineInventory,
  MdOutlinePeople,
  MdOutlinePersonalInjury,
  MdOutlinePinDrop,
  MdOutlinePsychology,
  MdOutlinePsychologyAlt,
  MdOutlineVerified,
  MdOutlineVpnKey,
} from "react-icons/md";
export const questions = [
  // Demographic Information
  {
    id: 0,
    service: "Demographic Information",
    text: "Gender",
    type: "MCQS",
    field: "gender",
    options: [
      {
        label: "Cis-Male",
        value: "Cis-Male",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Cis-Female",
        value: "Cis-Female",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Trans-Male",
        value: "Trans-Male",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Trans-Female",
        value: "Trans-Female",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Gender Fluid",
        value: "Gender Fluid",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Non-Binary",
        value: "Non-Binary",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Other-Please Specify",
        value: "Other",
        icon: <FaMale style={{ width: "100%", height: "100%" }} />,
      },
    ],
    nextQuestionId: "1",
  },

  {
    id: 1,
    service: "Demographic Information",
    text: "Age",
    type: "MCQS",
    field: "age",
    options: [
      { label: "0-14", value: "0-14", icon: null },
      { label: "15-24", value: "15-24", icon: null },
      { label: "25-34", value: "25-34", icon: null },
      { label: "35-44", value: "35-44", icon: null },
      { label: "45-54", value: "45-54", icon: null },
      { label: "55-64", value: "55-64", icon: null },
      { label: "65-74", value: "65-74", icon: null },
      { label: "75 or older", value: "75 or older", icon: null },
    ],
    nextQuestionId: "2",
  },

  {
    id: 2,
    service: "Demographic Information",
    text: "Race",
    type: "MCQS",
    field: "race",
    options: [
      { label: "White", value: "White" },

      {
        label: "Black or African American",
        value: "Black or African American",
      },
      { label: "Asian", value: "Asian" },
      {
        label: "American Indian/Alaska native",
        value: "American Indian/Alaska native",
      },
      {
        label: "Native Hawaiian or Pacific Islander",
        value: "Native Hawaiian or other Pacific Islander",
      },
      {
        label: "Unknown/Declined to Respond",
        value: "Unknown/Declined to Respond",
      },
      { label: "Other- Please specify", value: "Other" },
    ],
    nextQuestionId: "3",
  },

  {
    id: 3,
    service: "Demographic Information",
    text: "Ethnicity",
    type: "MCQS",
    field: "ethnicity",
    options: [
      { label: "Hispanic/Latino", value: "Hispanic/Latino" },
      { label: "Not Hispanic/Latino", value: "Other" },
    ],
    nextQuestionId: "4",
  },

  

  {
    id: 4,
    service: "Demographic Information",
    text: "Veteran",
    type: "MCQS",
    field: "is_veteran",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "Prefer not to disclose", value: "Prefer not to disclose" },
    ],
    nextQuestionId: "5",
  },

  {
    id: 5,
    service: "Demographic Information",
    text: "Zip code",
    type: "text",
    field: "zip_code",
    options: [
      {
        name: "zip_code",
        label: "Enter your code",
        value: "Enter your code",
        icon: <MdOutlinePinDrop style={{ width: "100%", height: "100%" }} />,
        placeholder: "Zip Code",
      },
    ],
    isLast: true,
    nextQuestionId: "6",
  },

  // CICIL Services
  {
    id: 6,
    service: "CICIL Services",
    text: "What service are you here for?",
    type: "MCQS",
    options: [
      {
        label: "TSA PreCheck",
        value: "TSA PreCheck",
        icon: <MdOutlineInventory style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Drop-In Center",
        value: "Drop-In Center",
        icon: (
          <MdOutlineHolidayVillage style={{ width: "100%", height: "100%" }} />
        ),
      },
      {
        label: "Employment Services",
        value: "Employment Services",
        icon: <MdDiversity2 style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Common Love DSM",
        value: "Common Love DSM",
        icon: <MdOutlineInterests style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "WALS(Work and Life Skills)",
        value: "WALS(Work and Life Skills)",
        icon: <MdOutlineHub style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Other-Please specify",
        value: "Other",
        icon: (
          <MdOutlineDesignServices style={{ width: "100%", height: "100%" }} />
        ),
      },
    ],
    nextQuestionId: "7",
  },

  {
    id: 7,
    service: "CICIL Services",
    text: "Do you identify as having a disability?",
    type: "MCQS",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      { label: "Unsure", value: "Unsure" },
      { label: "Prefer not to disclose", value: "Prefer not to disclose" },
    ],
    nextQuestionId: "8",
  },

  {
    id: 8,
    service: "CICIL Services",
    text: "What do you consider your primary disability?",
    type: "MCQS",
    options: [
      {
        label:
          "Physical (Ex. Amputation, Arthritis, Back Injury, Cardiac, Cerebral Palsy, Multiple Sclerosis, Muscular Dystrophy, Paraplegia, etc.)",
        value: "Physical",
        icon: <FaWheelchair style={{ width: "100%", height: "100%" }} />,
      },
      {
        label:
          "Cognitive (Ex. Developmental, Epilepsy, Learning Disability, Neurological Disorder etc.)",
        value: "Cognitive",
        icon: <MdOutlinePsychology style={{ width: "100%", height: "100%" }} />,
      },
      {
        label:
          "Mental/Emotional (Ex. Alzheimer's, Autism, Down Syndrome, Emotional/Behavioral Disabilities, Intellectual Disability, Mental Health Diagnosis, etc.)",
        value: "Mental/Emotional",
        icon: (
          <MdOutlinePsychologyAlt style={{ width: "100%", height: "100%" }} />
        ),
      },
      {
        label: "Hearing (Deafness, Hard of Hearing, Hearing Loss)",
        value: "Hearing",
        icon: <MdOutlineHearing style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Vision (Vision Impairment, Blindness)",
        value: "Vision",
        icon: <BsEye style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Other-Please specify",
        value: "Other",
        icon: (
          <MdOutlinePersonalInjury style={{ width: "100%", height: "100%" }} />
        ),
      },
    ],
    nextQuestionId: "9",
  },

  {
    id: 9,
    service: "CICIL Services",
    type: "MCQS",
    text: "Do you identify as part of the LGBTQ community?",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "10",
  },

  {
    id: 10,
    service: "CICIL Services",
    type: "MCQS",
    text: "Would you be interested in volunteering for events?",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "11",
  },

  {
    id: 11,
    service: "CICIL Services",
    text: "Contact Info",
    type: "text",
    options: [
      {
        label: "Name",
        value: "Name",
        icon: <BsPerson style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Email",
        value: "Email",
        icon: <GoMail style={{ width: "100%", height: "100%" }} />,
      },
    ],
    nextQuestionId: "12",
  },

  {
    id: 12,
    service: "CICIL Services",
    text: "Are you currently employed?",
    type: "MCQS",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    nextQuestionId: "13",
  },
  {
    id: 13,
    service: "CICIL Services",
    text: "How did you hear about us?",
    type: "MCQS",
    options: [
      { label: "other", value: "other" },
      { label: "Write your answer", vlaue: "Write your answer" },
    ],
    nextQuestionId: "14",
  },
  {
    id: 14,
    service: "CICIL Services",
    text: "Would you like additional information about services at CICIL?",
    type: "MCQS",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
      {
        label: "Please Enter your Email",
        value: "Please Enter your Email",
        icon: <GoMail style={{ width: "100%", height: "100%" }} />,
      },
    ],
    nextQuestionId: "15",
  },

  // Drop-In Center Check-In
  {
    id: 15,
    service: "Drop-In Center Check-In",
    text: "Drop-In Center Check-In",
    type: "text",
    options: [
      {
        name: "name",
        label: "Name",
        value: "Name",
        icon: <BsPerson style={{ width: "100%", height: "100%" }} />,
        placeholder: "Name",
      },
      {
        name: "time_in",
        label: "Time In",
        value: "Time In",
        icon: <CiLogin style={{ width: "100%", height: "100%" }} />,
        placeholder: "Time In",
        type: "time",
      },
      {
        name: "staff_name",
        label: "Staff Person",
        value: "Staff Person",
        icon: <BsPerson style={{ width: "100%", height: "100%" }} />,
        placeholder: "Staff Person",
      },
      {
        name: "staff_phone",
        label: "Staff Phone number",
        value: "Staff Phone number",
        icon: <MdOutlineCall style={{ width: "100%", height: "100%" }} />,
        placeholder: "Staff Phone Number",
        type: "tel",
      },
    ],
    nextQuestionId: "16",
    isLast: true,
  },

  // Drop-In Center Check-Out
  {
    id: 16,
    service: "Drop-In Center Check-Out",
    text: "Drop-In Center Check-Out",
    type: "text",
    options: [
      {
        name: "code",
        label: "Unique Code",
        value: "Unique Code",
        icon: <MdOutlineVpnKey style={{ width: "100%", height: "100%" }} />,
        placeholder: "Unique Code",
      },
      {
        name: "name",
        label: "Name",
        value: "Name",
        icon: <BsPerson style={{ width: "100%", height: "100%" }} />,
        placeholder: "Name",
      },
      {
        name: "time_out",
        label: "Time out",
        value: "Time out",
        icon: <CiLogin style={{ width: "100%", height: "100%" }} />,
        placeholder: "Time out",
        type: "time",
      },
    ],
    nextQuestionId: "17",
  },

  {
    id: 17,
    service: "Drop-In Center Check-Out",
    text: "What areas did you use today?",
    type: "MCQSIconMulti",
    field: "areas_used",
    options: [
      {
        label: "Socialization",
        value: "Socialization",
        icon: <MdOutlineVerified style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Arts & Crafts",
        value: "Arts & Crafts",
        icon: <MdOutlineDraw style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Attended a Group",
        value: "Attended a Group",
        icon: <MdOutlineGroups style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Independent Living Skills",
        value: "Independent Living Skills",
        icon: <MdOutlineCategory style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Fitness Center",
        value: "Fitness Center",
        icon: <MdFitnessCenter style={{ width: "100%", height: "100%" }} />,
      },
      {
        label: "Peer Support One-on-One",
        value: "Peer Support One-on-One",
        icon: <MdOutlinePeople style={{ width: "100%", height: "100%" }} />,
      },
    ],

    nextQuestionId: 18,
    isLast: true,
  },
];
