import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 255, 0.2); ; 
  backdrop-filter: blur(8px); 
  z-index: 0;
`;
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 366px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 20px;
  @media only screen and (min-width:320px) and (max-width:640px){
    width:251px;
    height:383px;
  }
`;

export const CloseIcon = styled.span`
 float: right;
  cursor: pointer;
  font-size: 24px;
  margin-top:-2%;
color: rgba(102, 112, 133, 1);

`;
export const HeadDiv=styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
export const Heading = styled.h2`
  font-weight: 600;
  font-family: 'Inter';
  font-size: 18px;
  line-height: 28px;
color:rgba(16, 24, 40, 1);
margin-top:4%;
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 16px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 14px;
  margin-top:7%;
}
`;
export const Heading2 = styled.h4`
font-family:'Inter';
font-weight: 400;
font-size: 14px;
line-height:20px;
margin-top:-2%;
@media only screen and (min-width:641px) and (max-width:810px){
  font-size: 16px;
}
@media only screen and (min-width:320px) and (max-width:640px){
  font-size: 12px;
  text-align:center;
  line-height: 13px;
  margin-top:-4%;
}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
justify-content: center;
 
`;
export const Input1=styled.input`
    width: 80px;
    height:80px;
    margin: 5px;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    border:2px solid rgba(14, 59, 114, 1);
    font-size:48px;
    font-family:'Inter';
    font-weight:500;
    letter-spacing: -2%;
    color:rgba(14, 59, 114, 1);
    @media only screen and (min-width:641px) and (max-width:810px){
      font-size:40px;
    }
    @media only screen and (min-width:320px) and (max-width:640px){
      font-size:16px;
      width:48px;
      height:48px;
    }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:11%;
  @media only screen and (min-width: 320px) and (max-width:640px){
    flex-direction: column;
    row-gap:10px;
  }
`;
export const Button = styled.button`
width:174px;
height:44px;
margin: 0 10px;
cursor: pointer;
border-radius:8px;
border:1px solid rgba(208, 213, 221, 1);
background-color:rgba(208, 213, 221, 1);
color:rgba(52, 64, 84, 1);
font-size: 16px;
font-family:'Inter';
font-weight:600;
`;
export const Button1 = styled.button`
width:174px;
height:44px;
margin: 0 10px;
cursor: pointer;
border-radius:8px;
background: linear-gradient(0deg, #0E2F58, #0E2F58),linear-gradient(0deg, #0E3B72, #0E3B72);
color: rgba(255, 255, 255, 1);
font-size: 16px;
font-family:'Inter';
font-weight:600;

`;



export const ImgCenter=styled.div`
align-items: center;
justify-content: center;
display: flex;
@media only screen and (min-width:320px) and (max-width:640px){
  margin-top:2%;
}
`;
export const Img1=styled.img`
width:24px;
height:24px;
`;
export const ImgBack=styled.div`
width:48px;
height:48px;
border-radius: 28px;
border:2px solid rgba(0, 158, 161, 0.04);
align-items: center;
justify-content: center;
display: flex;
`;