import styled from "styled-components";

export const ErrorContent = styled.div`
  margin-top: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledErrorContainer = styled.div`
  width: 100%;
  background-color: #ffe5e5;
  color: #ff0000;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 13px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 13px;
  }
`;

export const ErrorIcon = styled.div`
  margin-right: 8px;
  margin-top: 6px;
`;

export const ErrorMessage = styled.p`
  margin: 0;
`;
