import React from 'react';
import {devices} from '../../theme/devices'
import styled from 'styled-components';

export const ResponsiveImage = styled.img`
  width: 72px;
  height: 72px;
  @media ${devices.lg}{
	width:55px;
	height:55px
  }
  @media ${devices.md}{
	width:47px;
	height:47px
  }
  @media ${devices.sm}{
	width:47px;
	height:47px;
	margin-left:24%;
  }
`;

const Service1 = () => <ResponsiveImage src="/img1.png" alt="Mobile Icon" />;
const Service2 = () => <ResponsiveImage src="/img_2.png" alt="Mobile Icon" />;
const Service3 = () => <ResponsiveImage src="/img3.png" alt="MObile Icon" />;
const Service4 = () => <ResponsiveImage src="/img4.png" alt="Mobile Icon" />;


export const ServicesData = [
	{
		icon: <Service1 />,
        name: 'Demographic Information',
	
	},
	{
		
		icon: <Service3 />,
        name: 'CICIL Services',
	},
	{
		icon: <Service2 />,
        name: 'Drop-In Center Check-In',
	
	},
	{
		icon: <Service4 />,
		name: 'Drop-In Center Check-Out',
	},
];