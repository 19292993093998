import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 255, 0.2); ; 
  backdrop-filter: blur(8px); 
  z-index: 0;
`;
export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 366px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 20px;
  @media only screen and (min-width:320px) and (max-width:640px){
    width:251px;
    height:383px;
  }
`;

export const CloseIcon = styled.span`
 float: right;
  cursor: pointer;
  font-size: 24px;
  margin-top:-2%;
color: rgba(102, 112, 133, 1);

`;


export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
justify-content: center;
 margin-top:30%;
 margin-left:-40px;
 @media only screen and (min-width:320px) and (max-width:640px){
    margin-left:0px;
    margin-top:45%;
 }
`;
export const Input1=styled.input`
    margin-top: 9%;
    margin-bottom: 10px;
    padding: 8px;
  width:316px;
  height:50px;
    align-items: center;
    
    border-radius: 8px;
    border:2px solid rgba(14, 59, 114, 1);
    font-size:18px;
    font-family:'Inter';
    font-weight:500;
    letter-spacing: 0.5px;
    color:rgba(14, 59, 114, 1);
    margin-left:8%;
    &::placeholder{
    text-align: left;
}
    @media only screen and (min-width:641px) and (max-width:810px){
      font-size:18px;
    }
    @media only screen and (min-width:320px) and (max-width:640px){
      font-size: 12px;
    width: 193px;
    margin-left: -1%;
    margin-top: 19%;
    height: 50px;
    margin-bottom: 2px;
    }

`;
export const Label=styled.label`
font-size:29px;
color:#0e3b72;
margin:-10% -26px 0 0;
font-family:'Roboto';
font-weight:600;


@media only screen and (min-width:320px) and (max-width:640px){
    font-size: 24px;
    margin:-10% -3px 0 0;
}
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:11%;
  @media only screen and (min-width: 320px) and (max-width:640px){
    flex-direction: column;
    margin-top:21%;
  }
`;

export const Button1=styled.button`
align-items :center;
justify-content: center;
background-color: rgba(14, 59, 114, 1);
color:#fff;
width:150px;
height:48px;
border-radius: 10px;
`;


