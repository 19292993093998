import React,{useEffect,useState} from 'react';
import {Button,Col,Row,Form,Container} from 'react-bootstrap';
import styles from './demographicService.module.css';
import GenderImg from './../Assets/adminPanel/DemographicInfo/Gender.png';
import AgeImg from './../Assets/adminPanel/DemographicInfo/Age.png';
import RaceImg from './../Assets/adminPanel/DemographicInfo/Race.png';
import EthinicityImg from './../Assets/adminPanel/DemographicInfo/Ethinicity.png';
import VeteranImg from './../Assets/adminPanel/DemographicInfo/Veteran.png';
import ZipImg from './../Assets/adminPanel/DemographicInfo/ZipCode.png';
import Chart from "../../AdminPanel/AdminComponents/chart"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './demographicStyle.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx'; // Import xlsx library
import { ROOT_URL } from '../../../utils/variable';

function DemographicServices(){
    
    const navigate=useNavigate();

    const [genderPer,setgenderPercent]=useState([]);
    const [genderDesc,setgenderDesc]=useState([]);
    const [agePer,setagePercent]=useState([]);
    const [EthinicityPer,setEthinicityPer]=useState([]);
    const [ethinicityDesc,setethinicityDesc]=useState([]);
    const [raceDesc,setraceDesc]=useState([]);
    const [racePercent,setracePercent]=useState([]);
    const [veteranPercent,setVeteranPercent]=useState([]);
    const [zipCode,setzipCode]=useState([]);
    const [downloadData,setDownloadData]=useState([]);


    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchMode, setSearchMode] = useState(true); // Use searchMode to toggle functionality


    const [showagenderPercentage, setshowagenderPercentage] = useState(false);
    const togglegenderPercentageChart = () => {
      setTimeout(() => {
        setshowagenderPercentage(!showagenderPercentage);
      }, 300); 
    }
    const [showagePercentage, setshowagePercentage] = useState(false);
    const toggleAgePercentageChart = () => {
      setTimeout(() => {
        setshowagePercentage(!showagePercentage);
      }, 300); 
    }
    const [showRacePercentage, setshowRacePercentage] = useState(false);
    const toggleRacePercentageChart = () => {
      setTimeout(() => {
        setshowRacePercentage(!showRacePercentage);
      }, 300); 
    }
    const [showEthinictyPercentage, setshowEthinictyPercentage] = useState(false);
    const toggleEthinicityPercentageChart = () => {
      setTimeout(() => {
        setshowEthinictyPercentage(!showEthinictyPercentage);
      }, 300); 
    }
    const [showVeteranPercentage, setshowVeteranPercentage] = useState(false);
    const toggleVeteranPercentageChart = () => {
      setTimeout(() => {
        setshowVeteranPercentage(!showVeteranPercentage);
      }, 300); 
    }
    const [showZipCode, setsshowZipCode] = useState(false);
    const toggleZipCodeTable = () => {
      setTimeout(() => {
        setsshowZipCode(!showZipCode);
      }, 300); 
    }
   
    useEffect(()=>{
        const getData=async()=>{
            axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('Token')}`;
            axios.defaults.headers.post["Content-Type"] = "application/json";
            //console.log('token',localStorage.getItem('Token'));
            //Send get request and store data
            try{
                console.time();
                const response=await axios.get(`${ROOT_URL}/demoInfo/report`);
                console.timeEnd();
                if(response.data.status === 'error'){
                    console.log("Error in the data:",response.data)
                    //console.log("Response Status:",response.data.status)
                }
                else{
                    console.log('Original Response:',response.data.data)
                    dataHandler(response?.data?.data);
                    setDownloadData(response?.data?.data);
                }
            }
            catch(error){
                if (error && error.response && error.response.status === 401) {
                    //console.log("401 error");
                    navigate('/admin-login'); // Navigate to your error page
                }
            }
        }
        getData();
    },[navigate])
   
    const handleSubmit=async(e)=>{
        e.preventDefault();
        setSearchMode(!searchMode);
        if(searchMode){
            try{
                const response = await axios.get(`${ROOT_URL}/demoInfo/report`, {
                    params: {
                        start_date: fromDate, 
                        end_date: toDate,     
                    }
                });
                if(response.data.status === 'error'){
                    console.log("Error in the data:",response.data)
                    //console.log("Response Status:",response.data.status)
                }
                else{
                    console.log("Response",response.data)
                    dataHandler(response?.data?.data);
                    setDownloadData(response?.data?.data);
                }
            }catch(error){
                if (error && error.response && error.response.status === 401) {
                    //console.log("401 error");
                    navigate('/admin-login'); // Navigate to your error page
                }
            }
        }
        else{
            setFromDate('');
            setToDate('');
            window.location.reload();        
        }
    }
    const dataHandler = (response)=>{
        setgenderPercent(response?.gender_percentages);
        setgenderDesc(response?.gender_desc);
        setagePercent(response?.age_percentages);
        setracePercent(response?.race_percentages);
        setraceDesc(response?.race_desc);
        setEthinicityPer(response?.ethnicity_percentages);
        setethinicityDesc(response?.ethnicity_desc);
        setVeteranPercent(response?.is_veteran_percentages);
        setzipCode(response?.zipcodes);

    }
    const Backbtn=(e)=>{
        e.preventDefault();
        navigate(-1)
    }
    const PrintPage=(e)=>{
        e.preventDefault();
        window.print();
    }
    // Helper function to add a sheet to the workbook
const addSheetToWorkbook = (workbook, sheetName, dataObject) => {
    const dataArray = Object.entries(dataObject).map(([key, value]) => ({
      [sheetName]: key,
      Percentage: value,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataArray); // Convert the data to a worksheet
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName); // Append the worksheet to the workbook
  };
  
  // Helper function to add array-based data to a new sheet
  const addArraySheetToWorkbook = (workbook, sheetName, dataArray) => {
    const worksheet = XLSX.utils.json_to_sheet(
      dataArray.map((item, index) => ({
        Index: index,
        Value: item,
      }))
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  };
  
  // Helper function to add complex array-based data to a new sheet
  const addComplexArraySheetToWorkbook = (workbook, sheetName, dataArray) => {
    const worksheet = XLSX.utils.json_to_sheet(dataArray);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  };
  
    
    const downloadDataFunction=(e)=>{
        e.preventDefault();
        console.log('Data in download Funciton:',downloadData)
        const workbook = XLSX.utils.book_new(); // Create a new workbook

        // Add each category as a new sheet in the workbook
        addSheetToWorkbook(workbook, 'Gender Percentages', downloadData.gender_percentages);
        addSheetToWorkbook(workbook, 'Age Percentages', downloadData.age_percentages);
        addSheetToWorkbook(workbook, 'Race Percentages', downloadData.race_percentages);
        addSheetToWorkbook(workbook, 'Ethnicity Percentages', downloadData.ethnicity_percentages);
        addSheetToWorkbook(workbook, 'Is Veteran Percentages', downloadData.is_veteran_percentages);
    
        // If the data is already an array (like zip codes), convert it to a suitable format
        const zipcodesSheet = XLSX.utils.json_to_sheet(
          Object.entries(downloadData.zipcodes).map(([index, zip]) => ({
            'Id#': index,
            Zipcode: zip,
          }))
        );
        XLSX.utils.book_append_sheet(workbook, zipcodesSheet, 'Zipcodes');
    
        // Adding description sheets
        const addDescriptionSheet = (workbook, sheetName, descriptions) => {
          const worksheet = XLSX.utils.json_to_sheet(
            descriptions.map((desc, index) => ({
              Index: index,
              Description: desc,
            }))
          );
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        };
    
        addDescriptionSheet(workbook, 'Gender Descriptions', downloadData.gender_desc);
        addDescriptionSheet(workbook, 'Ethnicity Descriptions', downloadData.ethnicity_desc);
        addDescriptionSheet(workbook, 'Race Descriptions', downloadData.race_desc);
    
        // Write and download the workbook as an Excel file
        XLSX.writeFile(workbook, 'Demographic Information.xlsx'); // Name of the file to be downloaded
    }
    return(
        <div id='demographicInfo'>
            <div className={`${styles.container} container-fluid`}>
                <div className={`d-flex justify-content-between p-3`}>
                    <div>
                        <img src="./../Assets/adminPanel/login/image 20.png" alt="Logo" className={styles.logo} />
                    </div>
                    <div className='p-3'>
                        <Button variant="" type="submit" className={styles.logoutButton} onClick={Backbtn}>
                            BACK 
                            <span style={{fontSize:"18px"}}>
                            {'→'}
                            </span>
                        </Button>
                    </div>
                </div>
                <Container className='pb-5'>
                <Form method="POST" onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-center"> {/* Center the content horizontally */}
                    <Row className="justify-content-center"> {/* Center the row horizontally for all screen sizes */}
                        <Col xs="auto" className="text-center"> {/* Use xs="auto" to center the items horizontally for all screen sizes */}
                        <div className="form-group input-group input-daterange">
                            <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(new Date(date))}
                            placeholderText="From"
                            id="datepicker1"
                            type="date"
                            name="from_date"
                            className="p-2 date"
                            maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
                            dateFormat="dd-MM-yyyy"
                            required
                            />
                        </div>
                        </Col>
                        <Col xs="auto" className="text-center">
                        <div className="form-group input-group input-daterange">
                            <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(new Date(date))}
                            placeholderText="To"
                            id="datepicker2"
                            type="date"
                            className="p-2 date"
                            maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
                            dateFormat="dd-MM-yyyy"
                            required
                            />
                        </div>
                        </Col>
                        <Col xs="auto" className="text-center">
                        <Button type="submit" variant="year" className={`${styles.searchBtn}`}>
                        {searchMode ? 'Search' : 'Reset'}
                        </Button>
                        </Col>
                    </Row>
                    </div>
                </Form>
                </Container>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={togglegenderPercentageChart}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={GenderImg} alt='gradeimg' height={40} width={40} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showagenderPercentage ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Gender</h4>
                            </div>
                        </div>
                    </div>
                </div>
                 <figure className={`survey-table-fade ${showagenderPercentage ? 'show' : ''}`}>
                    {showagenderPercentage && <Chart chartData={genderPer} fullData={genderDesc} table="true" tableDescription="Other"/>}
                </figure>
                
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleAgePercentageChart}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={AgeImg} alt='gradeimg' height={40} width={50} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showagePercentage ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Age</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showagePercentage ? 'show' : ''}`}>
                    {showagePercentage && <Chart chartData={agePer} table="false" tableDescription="Age"/>}
                </figure>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleRacePercentageChart}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={RaceImg} alt='gradeimg' height={40} width={40} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showRacePercentage ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Race</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showRacePercentage ? 'show' : ''}`}>
                    {showRacePercentage && <Chart chartData={racePercent} fullData={raceDesc} table="true" tableDescription="Race"/>}
                </figure>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleEthinicityPercentageChart}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={EthinicityImg} alt='gradeimg' height={40} width={40} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showEthinictyPercentage ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Ethnicity</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showEthinictyPercentage ? 'show' : ''}`}>
                    {showEthinictyPercentage && <Chart chartData={EthinicityPer} fullData={ethinicityDesc} table="true" tableDescription="Ethinicty"/>}
                </figure>

                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleVeteranPercentageChart}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={VeteranImg} alt='gradeimg' height={50} width={40} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showVeteranPercentage ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Veteran</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showVeteranPercentage ? 'show' : ''}`}>
                    {showVeteranPercentage && <Chart  chartData={veteranPercent}  table="false" tableDescription="Other"/>}
                </figure> 
                <div className="row justify-content-center">
                    <div className="col-md-8 col-12 mt-2" style={{ textAlign: "center" }}>
                        <div className="box" onClick={toggleZipCodeTable}>
                            <div className="box-left">
                                <img className="mt-3 mx-2" src={ZipImg} alt='gradeimg' height={50} width={40} />
                            </div>
                            <div className="box-right" data-for="details">
                                <FontAwesomeIcon
                                    icon={showZipCode ? faChevronDown : faChevronRight}
                                    style={{
                                        cursor: "pointer",
                                        color: "#003861",
                                        padding: "25px 0px"
                                    }}
                                />
                            </div>
                            <div className="box-text">
                                <h4 className={`${styles.toggleTags} mt-4`}>Zip Code</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <figure className={`survey-table-fade ${showZipCode ? 'show' : ''}`}>
                    {showZipCode && <Chart  chartData="" fullData={zipCode} table="true" tableDescription="ZipCodes"/>}
                </figure>
                <div>
                    <div class='container-fluid'>
                        <Row className="py-4">
                            <Col xs={12} className="d-flex justify-content-end p-3">
                                <Button className={`${styles.downloadbtn} mx-3`} onClick={downloadDataFunction}>
                                    Download Data
                                </Button>
                                <Button className={`${styles.downloadbtn}`} onClick={PrintPage}>
                                    Print This Page
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div> 
            </div>
        </div>
    )
}
export default DemographicServices;