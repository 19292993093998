import styled from "styled-components";
import { devices } from "../../theme/devices";

export const StyledMain = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow:hidden;
  flex-shrink: 0;
  background-image: url(../Clip.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    gap: 30px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    gap: 30px;
  }
`;
export const StyledLogoContainer = styled.div`
  width: 90%;
  height: auto;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 95%;
  }
`;
export const StyledLogo = styled.img`
  width: 89px;
  height: auto;
  flex-shrink: 0;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  //   padding: 0px 0px 40px 50px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 80px;
    height: 76px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 102px;
    height: 97px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 74px;
    height: 70px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 74px;
    height: 70px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 48px;
    height: 45px;
  }
`;
export const StyledCross = styled.button`
  width: 21px;
  height: 21px;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  // @media only screen and (min-width: 1081px) and (max-width: 1280px) {
  //   width: 45.322px;
  //   height: 45.322px;
  // }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 45.322px;
    height: 45.322px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 45.322px;
    height: 45.322px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 24.13px;
    height: 24.13px;
  }
`;
export const StyledContainer = styled.div`
  width: 898px;
  height: auto;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.28);
  box-shadow: 0px 4px 37px 37px rgba(0, 0, 0, 0.17);
  backdrop-filter: blur(125px);
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: space-around;
  gap: 10px;
  padding:0px 0px 30px 0px;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 800px;
    gap: 5px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 839px;
    gap: 20px;
    margin: 5% 0% 0% 0%;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 655px;
    margin: 0% 0% 0% 0%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 528px;
    margin: 13% 0% 0% 0%;
    gap: 20px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    // width: 278px;
    width: 80%;
    margin: 25% 0% 0% 0%;
  }
`;
export const StyledH3 = styled.h3`
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 5px; /* 50% */
  margin-top: 5%;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    margin-top: 15%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 26px;
  }
`;
export const StyledLine = styled.div`
  width: 80%;
  height: 1px;
  background: #fff;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 70%;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 70%;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    width: 80%;
  }
`;
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 542.124px;
  height: 527.834px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(75px);
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 450px;
    height: 370.003px;
    margin-top: 2%;
    justify-content: center;
    gap: 10px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 543.673px;
    height: 539.194px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 508.673px;
    height: 504.483px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 450.657px;
    height: 446.945px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    // width: 227px;
    width: 80%;
    height: 309px;
  }
`;
export const StyledImgContainer = styled.div`
  width: 90%;
  height: auto;
  flex-shrink: 0;
  border-radius: 13px;
  background: #f4fffa;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 227.184px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 142px;
  }
`;
export const StyledImage = styled.img`
  width: 296.516px;
  height: 244.715px;
  background-size: cover;
  flex-shrink: 0;
  mix-blend-mode: multiply;
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    width: 290.432px;
    height: 185.694px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    width: 297.363px;
    height: 245.414px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    width: 278.22px;
    height: 229.615px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    width: 246.488px;
    height: 203.427px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    // width: 155px;
    width: 60%;
    height: 128px;
  }
`;
export const StyledP = styled.p`
  width: 90%;
  color: #070707;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: 0.5px;
  margin: 0;
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    font-size: 12px;
    line-height: 16.5px;
  }
`;
export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 72px;
  cursor: pointer;
  border-radius: 9px;
  background: #0e3b72;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  border: none;
  &: hover {
    // background: #249b9e;
    background: #10a37f;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1536px) {
    height: 68.694px;
  }
  @media only screen and (min-width: 1081px) and (max-width: 1280px) {
    height: 72px;
  }
  @media only screen and (min-width: 811px) and (max-width: 1080px) {
    height: 67px;
  }
  @media only screen and (min-width: 641px) and (max-width: 810px) {
    height: 60px;
  }
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    height: 39px;
    font-size: 16px;
  }
`;