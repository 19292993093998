import styled from "styled-components";
import { devices } from "../../theme/devices";
export const CheckmarkIcon = styled.span`
  width: 29px;
  height: 29px;
  background-color: rgba(0, 56, 97, 1);
  border-radius: 50%;
  position: absolute;
  color: #fff;
  top: -17%;
  left: -3%;
  padding: 0px;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  text-align: center;
  line-height: 29px;
  font-size: 20px;
  @media${devices.xxl} {
    width: 27.934px;
    height: 27.934px;
  }
  @media${devices.sm} {
    width: 15.23px;
    height: 15.23px;
    font-size: 14px;
    line-height: 15px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  margin: 4% 0;
  cursor: pointer;
  // width: 449px;
  // height: 100px;
`;
export const StyledInput = styled.div`
  width: 571px;
  height: 100px;
  flex-shrink: 0;
  border-radius: ${(props) => (props.checked ? "5px" : "10px")};
  border: ${(props) =>
    props.checked ? "1.5px solid #003861" : "1px solid #d9d9d9"};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: start-flex;
  align-items: center;
  gap: 30px;
  &: hover {
    border-radius: 5px;
    border: 1.5px solid #003861;
    background: #fff;
  }
  @media${devices.lg} {
    width: 506.556px;
    height: 88.714px;
  }
  @media${devices.md} {
    width: 496.77px;
    height: 87px;
  }
  @media${devices.sm} {
    width: 271.056px;
    height: 57px;
    gap: 15px;
  }
`;
export const StyledIcon = styled.div`
  width: ${(props) => (props.checked ? "55px" : "47px")};
  height: ${(props) => (props.checked ? "55px" : "47px")};
  color: ${(props) =>
    props.checked ? "rgba(43, 86, 131, 1)" : "rgba(217, 217, 217, 1)"};
  margin-left: 8%;
  ${StyledInput}:hover & {
    width: 55px;
    height: 55px;
    color: rgba(43, 86, 131, 1);
  }
  @media${devices.lg} {
    width: ${(props) => (props.checked ? "48.793px" : "41.696px")};
    height: ${(props) => (props.checked ? "48.793px" : "41.696px")};
    ${StyledInput}:hover & {
      width: 48.793px;
      height: 48.793px;
    }
  }
  @media${devices.md} {
    width: ${(props) => (props.checked ? "47.223px" : "40.354px")};
    height: ${(props) => (props.checked ? "47.223px" : "40.354px")};
    ${StyledInput}:hover & {
      width: 47.223px;
      height: 47.223px;
    }
  }
  @media${devices.sm} {
    width: ${(props) => (props.checked ? "31.702px" : "18.823px")};
    height: ${(props) => (props.checked ? "32px" : "21px")};
    ${StyledInput}:hover & {
      width: 31.702px;
      height: 32px;
    }
  }
`;
export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const StyledP = styled.p`
  color: ${(props) => (props.checked ? "#003861" : "#818590")};
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
  margin: 0;
  ${StyledInput}:hover & {
    color: #003861;
  }
  @media${devices.sm} {
    font-size: 14px;
    line-height: 10px;
  }
`;
export const StyledP1 = styled.p`
  color: #818590;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  margin: 0;
  @media${devices.sm} {
    font-size: 7px;
    line-height: 9.5px;
  }
`;
