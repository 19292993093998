import React from "react";
import {
  CheckmarkIcon,
  InputContainer,
  StyledIcon,
  StyledInput,
  StyledP,
  StyledP1,
  StyledText,
} from "./styles";
import { FaWheelchair } from "react-icons/fa";

function Input2({ label, value, icon, checked, handleClick, field, detail }) {
  return (
    <InputContainer onClick={() => handleClick(field, value)}>
      <CheckmarkIcon isVisible={checked}>&#10003;</CheckmarkIcon>
      <StyledInput checked={checked}>
        <StyledIcon checked={checked}>{icon}</StyledIcon>
        <StyledText>
          <StyledP checked={checked}>{label}</StyledP>
          <StyledP1>{detail}</StyledP1>
        </StyledText>
      </StyledInput>
    </InputContainer>
  );
}

export default Input2;
