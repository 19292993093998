import React from "react";
import StyledErrorComponent from "./index";

const Message = () => {
  return (
    <div>
      <StyledErrorComponent message="Choose an option that applies to you" />
    </div>
  );
};

export default Message;
