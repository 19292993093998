import styled, { css } from "styled-components";
import { devices } from "../../theme/devices";
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 3%;
`;
export const StyledP = styled.p`
  color: #003861;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  @media${devices.sm} {
    font-size: 14px;
  }
`;
export const StyledInput = styled.div`
  width: 400px;
  height: 90px;
  border-radius: 9px;
  border: ${(props) =>
    props.error ? "1.5px solid red" : "1.5px solid #003861"};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: start-flex;
  align-items: center;
  gap: 20px;
  //   &: hover {
  //     border-radius: 5px;
  //     border: 1.5px solid #003861;
  //     background: #fff;
  //   }
  @media${devices.xxl} {
    width: 381.501px;
    height: 86.325px;
  }
  @media${devices.md} {
    width: 375.02px;
    height: 84.882px;
  }
  @media${devices.sm} {
    width: 235.797px;
    height: 52.516px;
    gap: 15px;
  }
`;
export const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  color: #003861;
  margin-left: 5%;
  @media${devices.md} {
    width: 19.207px;
    height: 19.207px;
  }
  @media${devices.sm} {
    width: 15.011px;
    height: 15.011px;
  }
`;
export const Input = styled.input`
  ${(props) =>
    props.type === "time" &&
    css`
      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    `}
  border: none;
  outline: none;
  flex: 1;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @media${devices.sm} {
    font-size: 12px;
  }
`;
