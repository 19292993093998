import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import styles from './adminWelcomeScreen.module.css';
import { useNavigate } from 'react-router-dom';
function AdminWelcome() {
    const navigate=useNavigate();
    const CiCiLServices=(e)=>{
        e.preventDefault();
        navigate('/adminPanel/cicilServices');
    }
    const DemographicServices=(e)=>{
        e.preventDefault();
        navigate('/adminPanel/demographicServices');
    }

    const dropInReport=(e)=>{
        e.preventDefault();
        navigate('/adminPanel/dropIn-report');
    }
    
    const Logout=(e)=>{
        e.preventDefault();
        localStorage.clear();
        navigate('/admin-login')
    }
    return (
        <div className={`${styles.container} vh-100 d-flex flex-column`}>
            <div className={`d-flex justify-content-between p-3`}>
                <div>
                    <img src="./../Assets/adminPanel/login/image 20.png" alt="Logo" className={styles.logo} />
                </div>
                <div className='p-3'>
                    <Button variant="" type="submit" className={styles.logoutButton} onClick={Logout}>
                        Logout
                    </Button>
                </div>
            </div>
            <div className={`${styles.cardContainer} container-fluid d-flex flex-column justify-content-center flex-grow-1`}>
                <div className='container'>
                    <Row>
                        <Col>
                            <h4 className={`${styles.heading}`}>
                                Welcome Back!
                            </h4>
                        </Col>
                    </Row>
                    <Row className='text-center pt-5 pt-md-5'>
                        <Col xl={4}>
                            <Card className={`${styles.loginCard} mt-3`} onClick={CiCiLServices}>
                                <Card.Body>
                                    <div>
                                        <img src='../img_2.png' alt="Logo1" className={styles.logo} width='100px' height='100px'/>
                                    </div>
                                    <h4 className={`${styles.imageHead}`}>
                                        CICIL <br/>SERVICES
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card className={`${styles.loginCard} mt-3`} onClick={DemographicServices}>
                                <Card.Body>
                                    <div>
                                        <img src='../img1.png' alt="Logo2"  className={styles.logo} width='100px' height='100px'/>
                                    </div>
                                    <h4 className={`${styles.imageHead}`}>
                                        Demographic <br/>Information
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4}>    
                            <Card className={`${styles.loginCard} mt-3`} onClick={dropInReport}>
                                <Card.Body>
                                    <div>
                                        <img src='../inventory.png' alt="Logo3"  width='100px' height='100px' />
                                    </div>
                                    <h4 className={`${styles.imageHead}`}>
                                        Drop-In Center <br /> Check-In/Check-Out
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>                
            </div>
        </div>
    );
}

export default AdminWelcome;
