import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Section2 from "./components/section2/index";
import Section3 from "./components/section3/index";
import WelcomePage from "./components/WelcomePage";
import Input1 from "./components/Input1";
import Input3 from "./components/Input3";
import Submission from "./components/Submission";
import Input2 from "./components/Input2";
import OptionInput from "./components/Input";
import InputPopup from "./components/InputPopUp";
import ErrorPage from "./components/ErrorPage";
import CicilServices from "./components/CicilServices";
import AdminLogin from "./components/AdminPanel/adminLogin";
import AdminWelcome from "./components/AdminPanel/adminWelcomeScreen";
import CICICLServices from "./components/AdminPanel/CICLServices";
import DemographicServices from "./components/AdminPanel/DemographicServices";
import DropInCheckout from "./components/AdminPanel/DropIn";
import ErrorMessage from "./components/ErrorMessage/errorContent";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/services" element={<Section2 />} />
        <Route path="/section3/:serviceName" element={<Section3 />} />
        <Route path="/" element={<WelcomePage />} />
        <Route path="/input" element={<OptionInput />} />
        <Route path="/input1" element={<Input1 />} />
        <Route path="/input2" element={<Input2 />} />
        <Route path="/input3" element={<Input3 />} />
        <Route path="/submission" element={<Submission />} />
        <Route path="/inputpopup" element={<InputPopup />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/service/CICIL" element={<CicilServices />} />

        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-welcome" element={<AdminWelcome />} />
        <Route path="/adminPanel/cicilServices" element={<CICICLServices />} />
        <Route
          path="/adminPanel/demographicServices"
          element={<DemographicServices />}
        />
        <Route path="/adminPanel/dropIn-report" element={<DropInCheckout />} />

        <Route path="/errormessage" element={<ErrorMessage />} />
      </Routes>
    </Router>
  );
}

export default App;
