import styled, { css } from "styled-components";
import { devices } from "../../theme/devices";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 3%;
`;
export const StyledP = styled.p`
  color: #003861;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  @media${devices.sm} {
    font-size: 14px;
  }
`;
export const StyledInput = styled.div`
  width: 463px;
  height: 81px;
  border-radius: 9px;
  border: ${(props) =>
    props.error ? "1.5px solid red" : "1.5px solid #003861"};
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: start-flex;
  align-items: center;
  gap: 20px;
  //   &: hover {
  //     border-radius: 5px;
  //     border: 1.5px solid #003861;
  //     background: #fff;
  //   }
  @media${devices.md} {
    width: 444.637px;
    height: 77.788px;
  }
  @media${devices.sm} {
    width: 273px;
    height: 49px;
    gap: 15px;
  }
`;
export const StyledIcon = styled.div`
  width: 20px;
  height: 20px;
  color: #003861;
  margin-left: 5%;
  @media${devices.md} {
    width: 19.207px;
    height: 19.207px;
  }
  @media${devices.sm} {
    width: 15.011px;
    height: 15.011px;
  }
`;
export const StyledClock = styled.div`
  width: 20px;
  height: 20px;
  color: #0e3b72;
  margin-right: 5%;
  @media${devices.md} {
    width: 19.207px;
    height: 19.207px;
  }
  @media${devices.sm} {
    width: 15.011px;
    height: 15.011px;
  }
`;
export const CustomTimePicker = styled(TimePicker)`
  .rc-time-picker-input {
    border: none;
    outline: none;
    flex: 1;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 200px;
    line-height: 20px;
  }
  .rc-time-picker-panel * {
    box-sizing: border-box;
    width: 285px;
}
  .rc-time-picker-panel-inner {
    width: 250px; !important
    /* Add any other custom styles for the dropdown inner content */
  }
  .rc-time-picker-clear {
    display: none;
  }

  // /* Customize the clock dropdown appearance */
  // .rc-time-picker-panel-inner {
  //   background-color: yellow; /* Example: Change the dropdown background color */
  //   border: 1px solid blue; /* Example: Change the border of the dropdown */
  //   width: 250px !important;
  // }
  // .rc-time-picker-panel-select {
  //   width: 100px !important;
  // }
`;
// export const Popup = styled.div`
//   width: 100px;
//   height: 100px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
export const Input = styled.input`
  ${(props) =>
    props.type === "time" &&
    css`
      &::-webkit-clock-picker-indicator {
      }
    `}
  border: none;
  outline: none;
  flex: 1;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  @media${devices.sm} {
    font-size: 12px;
  }
`;
