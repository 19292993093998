import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  PopupContainer,
  CloseIcon,
  Heading,
  InputContainer,
  Input1,
  ButtonContainer,
  Button,
  ImgCenter,
  Img1,
  ImgBack,
  Heading2,
  HeadDiv,
  Button1,
  Backdrop,
} from "./style";

const Popup = ({ onClose, digits, onConfirm }) => {
  const codes = digits.split("");

  return ReactDOM.createPortal(
    <>
      <Backdrop />
      <PopupContainer>
        <CloseIcon onClick={onClose}>&times;</CloseIcon>
        <ImgCenter>
          <ImgBack>
            {" "}
            <Img1 src="../key.png" />
          </ImgBack>
        </ImgCenter>

        <HeadDiv>
          <Heading>Your Unique Code</Heading>
          <Heading2>
            Please remember this you will need this to check out.
          </Heading2>
        </HeadDiv>
        <InputContainer>
          {codes.map((code, index) => (
            <Input1 disabled key={index} type="text" value={code} />
          ))}
        </InputContainer>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <Button1 onClick={onConfirm}>Confirm</Button1>
        </ButtonContainer>
      </PopupContainer>
    </>,
    document.body
  );
};

export default Popup;
