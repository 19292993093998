import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import AdditionalInput from "../AdditionalInput/index";
import dayjs from "dayjs";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  StyledInfo,
  StyledDiv,
  StyledNavbar,
  NavBarLogo,
  CrossIcon,
  CrossImg,
  StyledQuestions,
  QuestionHeading,
  QuestionInput,
  InputField,
  OptionsNumber,
  CenteredContainer,
  CenteredColumn,
  CenteredRow,
  OtherButton,
  ArrowIcon,
  Icon1,
  Icon2,
  Button,
} from "./style";
import { questions } from "../data/inputData";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../formPopUp/index";
import OptionInput from "../Input";
import Input1 from "../Input1";
import Input3 from "../Input3";
import { ROOT_URL, URL_LIST } from "../../utils/variable";
import InputPopup from "../InputPopUp";
import moment from "moment";
import StyledErrorComponent from "../ErrorMessage";
const Section3 = () => {
  const { serviceName } = useParams();
  const [state, setState] = useState({
    time_in: moment().format("YYYY-MM-DD HH:mm:ss"),
    time_out: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
  const [time, setTime] = useState(moment());
  const [currentServiceQuestionIndex, setCurrentServiceQuestionIndex] =
    useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [optionValue, setOptionValue] = useState({});
  const [otherField, setOtherField] = useState(null);
  const [otherFieldValue, setOtherFieldValue] = useState(null);
  const [isOtherOpen, setIsOtherOpen] = useState(false);
  const [randomDigit, setRandomDigit] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [prevQuestionButton, setPrevQuestionButton] = useState(true);
  const [isNextButton, setIsNextButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const initialQuestionIndex = questions.findIndex(
      (question) => question.service === serviceName
    );
    setCurrentServiceQuestionIndex(
      initialQuestionIndex >= 0 ? initialQuestionIndex : 0
    );
  }, [serviceName]);

  const handleOptionClick = (optionIndex) => {
    const currentQuestion = questions.find(
      (question) => question.id === currentServiceQuestionIndex
    );

    if (!currentQuestion) {
      return;
    }

    const nextQuestionId = currentQuestion.nextQuestionId[optionIndex];

    const nextQuestion = questions.find(
      (question) =>
        question.id === nextQuestionId && question.service === serviceName
    );

    if (nextQuestion) {
      setCurrentServiceQuestionIndex(nextQuestion.id);
    } else {
      setCurrentServiceQuestionIndex(0);
    }

    if (currentQuestion.service === "Drop-In Center Check-In") {
      setIsPopupOpen(true);
    }
  };

  const handlePrevClick = () => {
    const prevQuestionIndex = currentServiceQuestionIndex - 1;
    if (
      prevQuestionIndex === 0 ||
      prevQuestionIndex === 15 ||
      prevQuestionIndex === 16
    ) {
      setPrevQuestionButton(true);
    } else {
      setPrevQuestionButton(false);
    }
    if (prevQuestionIndex >= 0) {
      const prevQuestion = questions[prevQuestionIndex];
      if (prevQuestion.service === serviceName) {
        setCurrentServiceQuestionIndex(prevQuestionIndex);
      }
    }
  };

  const handleNextClick = () => {
    const nextQuestionIndex = currentServiceQuestionIndex + 1;
    if (
      nextQuestionIndex === 0 ||
      nextQuestionIndex === 15 ||
      nextQuestionIndex === 16
    ) {
      setPrevQuestionButton(true);
    } else {
      setPrevQuestionButton(false);
    }
    if (nextQuestionIndex < questions.length) {
      const nextQuestion = questions[nextQuestionIndex];
      if (nextQuestion.service === serviceName) {
        setCurrentServiceQuestionIndex(nextQuestionIndex);
      }
    }
  };

  const handleCrossIconClick = () => {
    navigate("/");
  };

  useEffect(()=>{
    console.log("Condition:", state[currentQuestion?.field] === "Other");
    console.log("state[`${currentQuestion.field}_desc`]",state[`${currentQuestion?.field}_desc`])
  })
  const handleMultiClick = (field, value) => {
    //console.log('Other Field Description:', state[`${currentQuestion.field}_desc`]);
    setIsNextButton(false);
    if (field === "areas_used") {
      if (state?.areas_used?.length > 0) {
        const areaUsed = state.areas_used;
        const index = areaUsed.indexOf(value);

        if (index !== -1) {
          areaUsed.splice(index, 1);
          setState({ ...state, areas_used: [...areaUsed] });
          setOptionValue({
            ...optionValue,
            areas_used: [...areaUsed],
          });
        } else {
          setState({ ...state, areas_used: [...state.areas_used, value] });
          setOptionValue({
            ...optionValue,
            areas_used: [...optionValue.areas_used, value],
          });
        }
      } else {
        setState({ ...state, areas_used: [value] });
        setOptionValue({ ...optionValue, areas_used: [value] });
      }
    } else if (value === "Other") {
      setIsNextButton(true);
      setState({ ...state, [field]: value });
      setOptionValue({ ...optionValue, [field]: value });
      setOtherField(`${field}_desc`);
      setIsOtherOpen(true);
      console.log('Option values:',optionValue)
      console.log('Other field value',(`${field}_desc`))
    } else {
      if (state[field] === value) {
        setState({ ...state, [field]: null });
        setOptionValue({ ...optionValue, [field]: null });
      } else {
        setState({ ...state, [field]: value });
        setOptionValue({ ...optionValue, [field]: value });
      }
    }
  };

  function validatePhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the cleaned phone number has exactly 10 digits and contains only numbers
    if (cleanPhoneNumber.length === 10 && /^\d+$/.test(cleanPhoneNumber)) {
      return true;
    }

    return false;
  }
  function hasOnlyFourDigits(str) {
    // Use a regular expression to match exactly four digits
    const pattern = /^\d{4}$/;
    return pattern.test(str);
  }
  const handleChange = (e) => {
    if (e.target.name === "staff_phone") {
      const phoneNumber = e.target.value;
      const validatedPhone = validatePhoneNumber(phoneNumber);
      if (validatedPhone) {
        setState({ ...state, [e.target.name]: phoneNumber });
      } else {
        setState({ ...state, [e.target.name]: null });
      }
    } else if (e.target.name === "code") {
      const isValidCode = hasOnlyFourDigits(e.target.value);
      if (isValidCode) {
        setState({ ...state, [e.target.name]: e.target.value });
      } else {
        setState({ ...state, [e.target.name]: null });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = () => {

    const url = `${ROOT_URL}${URL_LIST[serviceName]}`;
    const formattedData = {
      gender: state.gender,
      gender_desc: state.gender === "Other" ? state.gender_desc : "",
      age: state.age,
      ethnicity: state.ethnicity,
      ethnicity_desc: state.ethnicity === "Other" ? state.ethnicity_desc : "",
      race: state.race,
      race_desc: state.race === "Other" ? state.race_desc : "",
      is_veteran: state.is_veteran,
      zip_code: state.zip_code,
    };
    console.log('Before submitting data:',formattedData)
    axios.post(url, formattedData).then((res) => {
      if (res.data.status === "success") {
        navigate("/submission");
      } else {
        navigate("/error");
      }
    });
  };
  const handleOtherChange = (e) => {
    setIsNextButton(false);
    //console.log('e.target.name',e.target.name)
    if (e.target.value === "" || e.target.value === null) {
      setIsNextButton(true);
    } else {
      setIsNextButton(false);
    }
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("otherField",state)
    
  };
  function generateRandom4DigitCode() {
    // Generate a random number between 1000 and 9999 (inclusive)
    const min = 1000;
    const max = 9999;
    const randomCode = Math.floor(Math.random() * (max - min + 1)) + min;

    // Ensure the code is a 4-digit number (prepend '0' if necessary)
    const codeString = randomCode.toString().padStart(4, "0");

    return codeString;
  }
  const handleCheckIn = () => {
    if (state.name === null || state.name === "" || state.name === undefined) {
      setErrorMessage(["Name is required"]);
      setState({ ...state, name: null });
    } else if (
      state["time_in"] === null ||
      state["time_in"] === "" ||
      state["time_in"] === undefined
    ) {
      setErrorMessage(["Time is required"]);
      setState({ ...state, time_in: null });
    } else if (
      state["staff_name"] === null ||
      state["staff_name"] === "" ||
      state["staff_name"] === undefined
    ) {
      setErrorMessage(["Staff Person is required"]);
      setState({ ...state, staff_name: null });
    } else if (
      state["staff_phone"] === null ||
      state["staff_phone"] === "" ||
      state["staff_phone"] === undefined
    ) {
      setErrorMessage(["Staff Phone is not correct"]);
      setState({ ...state, staff_phone: null });
    } else {
      const randomFourDigits = generateRandom4DigitCode();
      const url = `${ROOT_URL}${URL_LIST[serviceName]}`;
      axios
        .post(url, { ...state, code: randomFourDigits })
        .then((res) => {
          if (res.data.status === "success") {
            setRandomDigit(randomFourDigits);
            setIsPopupOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function getAllArrayValues(obj) {
    const valuesArray = Object.values(obj); // Get all values from the object
    const arrays = valuesArray.filter((value) => Array.isArray(value)); // Filter arrays from the values
    const result = [].concat.apply([], arrays); // Merge arrays into a single array
    return result;
  }
  const handleCheckOut = () => {
    setErrorMessage(null);
    if (
      state["time_out"] === null ||
      state["time_out"] === "" ||
      state["time_out"] === undefined
    ) {
      setErrorMessage(["Time is required"]);
      setState({ ...state, time_out: null });
    } else if (
      state.name === null ||
      state.name === "" ||
      state.name === undefined
    ) {
      setErrorMessage(["Name is required"]);
      setState({ ...state, name: null });
    } else if (
      state.code === null ||
      state.code === "" ||
      state.code === undefined
    ) {
      setErrorMessage(["Code is required"]);
      setState({ ...state, code: null });
    } else {
      const url = `${ROOT_URL}${URL_LIST[serviceName]}`;
      //console the data
      console.log("Data before uploading:", state);
      axios
        .post(url, {
          name: state.name,
          code: state.code,
          areas_used: state.areas_used,
          time_out: state.time_out,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            navigate("/submission");
          } else if (res.data.status === "error") {
            setErrorMessage(getAllArrayValues(res.data.message));
          }
        })
        .catch((err) => {
          navigate("/error");
        });
    }
  };
  const handleChangeTime = (value) => {
    setTime(value);
    setState({
      ...state,
      time_in: value.format("YYYY-MM-DD HH:mm:ss"),
      time_out: value.format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  const handleRandomConfirm = () => {
    navigate("/");
  };
  const currentQuestion = questions.find(
    (question) =>
      question.id === currentServiceQuestionIndex &&
      question.service === serviceName
  );
  const isLastDemographicQuestion =
    currentQuestion?.service === "Demographic Information" &&
    currentQuestion?.id ===
      questions
        .filter((question) => question.service === "Demographic Information")
        .slice(-1)[0]?.id;

  return (
    <StyledInfo>
      <StyledDiv>
        <StyledNavbar>
          <NavBarLogo src="../logo1.png" />
          <CrossIcon onClick={handleCrossIconClick}>
            <CrossImg src="../cross1.png" />
          </CrossIcon>
        </StyledNavbar>

        <StyledQuestions>
          {currentQuestion && (
            <>
              <QuestionHeading>{currentQuestion.text}</QuestionHeading>
              <QuestionInput>
                {errorMessage &&
                  errorMessage.length > 0 &&
                  errorMessage.map((message) => {
                    return <StyledErrorComponent message={message} />;
                  })}
                <InputField>
                  {currentQuestion.options.map((option, index) => {
                    if (currentQuestion.type === "MCQSIcon") {
                      return (
                        <OptionInput
                          field={currentQuestion.field}
                          label={option.label}
                          icon={option.icon}
                          checked={
                            option.value === optionValue[currentQuestion.field]
                          }
                          value={option.value}
                          handleClick={handleMultiClick}
                        />
                      );
                    } else if (currentQuestion.type === "MCQS") {
                      return (
                        <Input1
                          field={currentQuestion.field}
                          content={option.label}
                          value={option.value}
                          handleClick={handleMultiClick}
                          checked={
                            option.value === optionValue[currentQuestion.field]
                          }
                        />
                      );
                    } else if (currentQuestion.type === "text") {
                      return (
                        <Input3
                          onChange={
                            option.name === "time_in" ||
                            option.name === "time_out"
                              ? handleChangeTime
                              : handleChange
                          }
                          icon={option.icon}
                          name={option.name}
                          label={option.label}
                          placeholder={option.placeholder}
                          type={option.type}
                          value={
                            option.type === "time" ? time : state[option.name]
                          }
                          error={
                            state[option.name] === null ||
                            state[option.name] === ""
                          }
                        />
                      );
                    } else if (currentQuestion.type === "MCQSIconMulti") {
                      return (
                        <OptionInput
                          field={currentQuestion.field}
                          label={option.label}
                          icon={option.icon}
                          checked={
                            currentQuestion.field === "areas_used" &&
                            optionValue?.areas_used?.length > 0 &&
                            optionValue.areas_used.includes(option.value)
                          }
                          value={option.value}
                          handleClick={handleMultiClick}
                        />
                      );
                    }
                  })}
                  {state[currentQuestion.field] === "Other" && (
                    <AdditionalInput
                      onChange={handleOtherChange}
                      name={`${currentQuestion.field}_desc`}
                      label="Please Specify"
                      placeholder="Please Enter the Value"
                      value={state[`${currentQuestion.field}_desc`]}
                    />
                  )}
                  {currentQuestion.type === "text" &&
                    currentQuestion.text !== "Drop-In Center Check-In" &&
                    currentQuestion.text !== "Drop-In Center Check-Out" && (
                      <Button onClick={handleSubmit}>Submit</Button>
                    )}
                  {currentQuestion.service === "Drop-In Center Check-In" && (
                    <Button disabled={false} onClick={handleCheckIn}>
                      Submit
                    </Button>
                  )}
                  {currentQuestion.service === "Drop-In Center Check-Out" &&
                    currentQuestion["text"] ===
                      "What areas did you use today?" && (
                      <Button disabled={false} onClick={handleCheckOut}>
                        Submit
                      </Button>
                    )}
                  {/* {isLastDemographicQuestion && (
                    <Button onClick={handleSubmitButton}>Submit</Button>
                  )} */}
                </InputField>
              </QuestionInput>
            </>
          )}
        </StyledQuestions>

        <ArrowIcon>
          {!prevQuestionButton && (
            <Icon1 onClick={handlePrevClick}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  cursor: "pointer",
                  color: "#003861",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Icon1>
          )}
          {!isNextButton && !currentQuestion?.isLast && (
            <Icon2 onClick={handleNextClick}>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  cursor: "pointer",
                  color: "#003861",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Icon2>
          )}
        </ArrowIcon>
      </StyledDiv>
      {isPopupOpen && randomDigit !== null && (
        <Popup
          digits={randomDigit}
          onClose={() => setIsPopupOpen(false)}
          onConfirm={handleRandomConfirm}
        />
      )}
      {/* {isOtherOpen && (
        <InputPopup
          handleInputChange={handleOtherChange}
          handleSubmit={handleOtherSubmit}
          onClose={() => setIsOtherOpen(false)}
        />
      )} */}
      <CenteredContainer>
      <CenteredRow>
        <CenteredColumn>
          {!prevQuestionButton && (
            <OtherButton type='submit' onClick={handlePrevClick}>
              Previous
            </OtherButton>
          )}
        </CenteredColumn>
        <CenteredColumn>
          {!isNextButton && !currentQuestion?.isLast && (
            <OtherButton type='submit' onClick={handleNextClick}>
              Next
            </OtherButton>
          )}
        </CenteredColumn>
      </CenteredRow>
    </CenteredContainer>
    </StyledInfo>
  );
};

export default Section3;
