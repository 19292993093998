export const size = {
  xs: "319px",
  sm: "639px",
  md: "809px",
  lg: "1079px",
  xl: "1199px",
  xxl: "1439px",
  xsu: "320px",
  smu: "640px",
  mdu: "810px",
  lgu: "1080px",
  xlu: "1200px",
  xxlu: "1440px",
};

export const devices = {
  xsu: `(min-width: ${size.xsu})`,
  smu: `(min-width: ${size.smu})`,
  mdu: `(min-width: ${size.mdu})`,
  lgu: `(min-width: ${size.lgu})`,
  xlu: `(min-width: ${size.xlu})`,
  xxlu: `(min-width: ${size.xxlu})`,
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
};
