import React, { useState } from "react";
import TimePicker from "rc-time-picker";
import moment from "moment";
import {
  ClockFace,
  CustomTimePicker,
  Input,
  StyledClock,
  StyledContainer,
  StyledIcon,
  StyledInput,
  StyledP,
} from "./styles";
import { BsClock } from "react-icons/bs";
function Input3({
  icon,
  label,
  placeholder,
  name,
  onChange,
  type,
  error,
  value,
}) {
  return (
    <StyledContainer>
      <StyledP>{label}</StyledP>
      <StyledInput error={error}>
        <StyledIcon>{icon}</StyledIcon>
        {type === "time" && (
          // <Input
          //   type="time"
          //   name={name}
          //   placeholder={placeholder}
          //   onChange={onChange}
          // />
          <CustomTimePicker
            // name={name}

            // placeholder={placeholder}
            value={value}
            onChange={onChange}
            showSecond={false}
            defaultValue={moment()}
            format="HH:mm" // Display in 24-hour format
            // inputReadOnly
            // popupStyle={Popup}
          />
        )}
        {type === "tel" && (
          <Input
            type="tel"
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
        {!type && (
          <Input
            type={type || "text"}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
      </StyledInput>
    </StyledContainer>
  );
}

export default Input3;
